import React, { Component } from "react";
import Header from './header-v2';
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import "../assets/styles/404.scss";


const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}


class page404 extends Component {

    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentDidMount(){

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }


    componentWillReceiveProps(nextProps) {


    }


    render() {

        return (

            <div className="page-wrap">

                <div>

                    <Header />

                    <main>
                        <div className="container-fluid">
                            <div className="wrap">

                                <div className="wrap-404">
                                    <h1>404</h1>
                                    <h3>Page not Found</h3>
                                </div>

                            </div>
                        </div>
                    </main>
                </div>

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(page404));