import React, { Component } from "react";
import axios from 'axios';
//import { Link } from "react-router-dom";
import Dropzone from 'react-dropzone'
import Editor  from './editor';
import { EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';


//import {stateToHTML} from 'draft-js-export-html';
//import {stateFromHTML} from 'draft-js-import-html';
//import { createEditorStateWithText } from 'draft-js-plugins-editor';

import { connect } from "react-redux";
import { cacheContent } from "../../../redux-js/actions/index";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        cacheContent: content => dispatch(cacheContent(content))
    };
}



class RequestForm extends Component {

    constructor(props) {
        super(props);


        let plainText = "";

        //console.log(localStorage.getItem("request_content"));

        if (localStorage.getItem("request_content")){
            plainText = localStorage.getItem("request_content");
        }


        const blocksFromHtml = htmlToDraft(plainText);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);


        this.state = {
            client:{},
            name: 'Guest',
            photo: process.env.REACT_APP_URL + '/images/avatar.jpg',
            errorMsg: '',
            msg: '',
            subject: '',
            content: '',
            attachments: [],
            btnEnabled: true,
            cache_key: '',
            email:'',
            first_name:'',
            last_name:'',
            editorState: editorState//createEditorStateWithText((plainText))
        };





        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);

        this.handleDropFiles    = this.handleDropFiles.bind(this);

        this.editorContent      = this.editorContent.bind(this);


        this.theEditor = React.createRef();




    }



    getClientInfo(){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/client/get-info/'+ this.props.match.params.client_slug + '/project-scope/')

            .then(res => {

                if (res.data.slug){

                    this.setState({
                        client: res.data,
                        name: res.data.company,
                        photo: res.data.photo,
                    });

                    //console.log(res.data);

                } else{


                }
            })

            .catch((error) => {


            });

    }

    componentDidMount(){


        if (this.props.client_slug){

            this.setState({cache_key: 'requests-' + this.props.client_slug});

        }

        if(this.props.current_user.role === 'client' && this.props.current_user.slug) {

            this.setState({
                //first_name: this.props.current_user.first_name,
                //last_name: this.props.current_user.last_name,
                //email: this.props.current_user.email,
                name: this.props.current_user.company,
                photo: this.props.current_user.photo,
            });

        }else if (this.props.match.params.client_slug){

            this.getClientInfo();
        }


        let ds = this;

        window.$j(document).on('click','.editor-placeholder',function () {

            window.$j(this).css({display:'none'});

            if(ds.theEditor.current) {
                ds.theEditor.current.focus();
            }

        });

        //console.log(this.props);

    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });



    }


    editorContent(content){

        this.setState({content:content});

        localStorage.setItem("request_content",content);
        //stateToHTML(window.testsasfdasdf.current.editor.getEditorState().getCurrentContent())

    }

    handleSubmit(event) {

        event.preventDefault();

        this.setState({'btnEnabled': false});


        let formData = new FormData();

        this.state.attachments.map((file) => {
            return formData.append('Files[]',file);
        });

        formData.append('subject', this.state.subject);
        formData.append('email', this.state.email);
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('content', this.state.content);
        formData.append('client_slug', this.state.client.slug);



        var headers = {};

        if ( JSON.parse(localStorage.getItem('currentUser')).token){
            headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token, 'Content-Type': 'multipart/form-data'} };
        }


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/support-requests-v2', formData , headers)
            .then(res => {

                //console.log(res);
                //console.log(res.data);

                if (res.data.success){

                    this.setState({
                        email: '',
                        first_name: '',
                        last_name: '',
                        subject: '',
                        msg: res.data.msg,
                        errorMsg: '',
                        attachments: []
                        //editorState: EditorState.createEmpty()// NOT proper way to reset
                    });

                    localStorage.setItem("request_content", "");


                    if (this.state.cache_key){
                        //clear the cache
                        this.props.cacheContent({key: this.state.cache_key, content: '' });

                    }

                    this.theEditor.current.clearEditor();


                }else {

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({'btnEnabled': true});
            })

            .catch((error) => {

                //console.log(error.response.data);
                this.setState({
                    errorMsg: error.response.data.message
                });
                this.setState({'btnEnabled': true});

            });


    }


    handleDropFiles (acceptedFiles){

        let attachments = this.state.attachments;


        for (var i = 0; i < acceptedFiles.length; i++){
            attachments.push(acceptedFiles[i]);
        }

        this.setState({attachments:attachments});

    }

    deleteAttachment(e, idx){


        let attachments = this.state.attachments;

        attachments.splice(idx, 1);
        this.setState({attachments: attachments});


        //console.log(this.state.attachments);
    }



    render() {



        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;


        if(errorMsg){
            errorMsg = <AlertDanger state={this.state} />
        }

        if (msg){
            msg = <AlertSuccess state={this.state} />
        }


        return (
            <form className="request-form" onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>

                <div className="form-group">
                    <div className="form-label">Subject</div>
                    <input placeholder="" name="subject" type="text" value={this.state.subject} onChange={this.handleInputChange} className="form-control" />
                </div>

                <div className="form-group">


                    <div className="draftjs-editor-wrap">


                        <Editor
                            editorState={this.state.editorState}
                            ref={this.theEditor}
                            onChange={this.editorContent}
                            placeholder=""
                        />

                        <div className="editor-placeholder">
                            <div className="editor-placeholder-inner">
                                <div className="user-photo"><img src={this.state.photo} alt=""/></div>
                                Hello {this.state.name},<br /><br />
                                Please describe your request here via message.
                            </div>
                        </div>


                        <Dropzone
                            onDrop={this.handleDropFiles}

                            accept="image/jpeg, image/png, image/jpg, image/gif, application/pdf, application/zip, application/atom+xml, audio/mp4, video/mp4,application/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/vnd.adobe.photoshop, text/csv, text/plain, text/html, application/postscript, "
                            multiple={true}
                            //maxSize={200000}
                        >

                            {({getRootProps, getInputProps}) => (
                                <div className="attach-file-btn" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <span className="attach-icon"></span>
                                </div>
                            )}
                        </Dropzone>





                    </div>

                    {
                        this.state.attachments &&
                        <div className="attachments">

                            {this.state.attachments.map((attachment,i) =>
                                <div className="attachment-item item-row" key={i}>
                                        <span onClick={((e) => this.deleteAttachment(e, i))}>&times;</span>
                                        {attachment.name}
                                </div>
                            )}

                        </div>

                    }

                </div>



                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <div className="form-label">Contact Email Address</div>
                            <input placeholder="" name="email" type="text" value={this.state.email} onChange={this.handleInputChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <div className="form-label">First Name</div>
                            <input placeholder="" name="first_name" type="text" value={this.state.first_name} onChange={this.handleInputChange} className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <div className="form-label">Last Name</div>
                            <input placeholder="" name="last_name" type="text" value={this.state.last_name} onChange={this.handleInputChange} className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="text-center">

                    <div style={{padding: '20px 0 30px'}}><p style={{fontSize:'.9em', lineHeight:1.5}}>Please input the contact information of the person we should update for this request.</p></div>

                    <div className="form-submit">
                        <button disabled={!this.state.btnEnabled} className="btn btn-primary" type="submit"><span>Submit To D6</span></button>
                    </div>

                    {
                        !this.state.btnEnabled &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }

                    {errorMsg}
                    {msg}

                </div>


            </form>
        );
    }
}




function AlertDanger(props) {

    let msg = (
        <div className="alert alert-danger">
            <div dangerouslySetInnerHTML={{__html: props.state.errorMsg}}></div>

        </div>
    );


    return ( msg );

}

function AlertSuccess(props) {


    let msg = (
        <div className="alert alert-success">
            <div dangerouslySetInnerHTML={{__html: props.state.msg}}></div>
        </div>
    );

    return ( msg );
}





export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
