import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';
//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
import moment from 'moment';
//import { Editor } from '@tinymce/tinymce-react';


import ReportsPageDescription from './partials/report-page-description';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';
//import moment from 'moment';
//import Dropzone from 'react-dropzone';
import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ReportsKeywordTracking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnEnabled: true,
            current_user: {},
            client_slug: '',
            delete_confirm: false,
            current_page: {
                id: 0,
                title: '',
                description: '',
                url: '',
            },
            sitewide_rankings: [],
            reports_fetched: false,
            show_pages_dropdown: false,
            current_report_year_month: '',
            keywords_position_distribution_chart: [],
            kps_chart_render: false
        };



        this.newPage                    = this.newPage.bind(this);
        this.handleInputChange          = this.handleInputChange.bind(this);
        this.handleSubmit               = this.handleSubmit.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // this.setState({
        //     [name]: value
        // });

        this.setState({
            current_page: {...this.state.current_page, [name]: value}
        })

    }


    handleSubmit(event) {


        event.preventDefault();

        this.setState({'btnEnabled': false});


        let formData = new FormData();



        formData.append('client', this.state.client_slug);
        formData.append('title', this.state.current_page.title);
        formData.append('url', this.state.current_page.url);


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/add-sitewide-rankings?report_year_month='+this.state.current_report_year_month, formData , authHeader())
            .then(res => {


                if (res.data.errors){

                    alert(res.data.errors)

                }else {

                    this.setState({
                        sitewide_rankings: this.state.sitewide_rankings.concat(res.data.new_page),
                        current_page: res.data.new_page,
                    });

                }

                this.setState({'btnEnabled': true});
            })

            .catch((error) => {

                //console.log(error.response.data);
                this.setState({
                    errorMsg: error.response.data.message
                });
                this.setState({'btnEnabled': true});

            });


    }




    getReportDetails(props){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/sitewide-rankings/?report_year_month='+this.state.current_report_year_month )
            .then(res => {


                let sitewide_rankings = res.data.sitewide_rankings;
                let keywords_position_distribution_chart = res.data.keywords_position_distribution_chart;

                if (sitewide_rankings.length > 0){
                    this.setState({
                        current_page: sitewide_rankings[0],
                    })
                }

                this.setState({
                    reports_fetched: true,
                    sitewide_rankings: sitewide_rankings ,
                    highlights: res.data.highlights,
                    keywords_position_distribution_chart: keywords_position_distribution_chart
                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){


        this.setState({
            current_report_year_month   : this.props.current_report_year_month,
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug
        },function () {
            this.getReportDetails(this.props);
        });



    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }


    setCurrentPage(page){

        this.setState({
            current_page: page
        });
    }

    newPage(){

        this.setState({
            current_page: {id: 0, title: '', spreadsheet_key: '', description: ''},
        });

    }


    deleteConfirmed(){

        var ds = this;


        let formData = new FormData();

        formData.append('id', this.state.current_page.id);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/delete-sitewide-rankings', formData, authHeader())
            .then(res => {


            }).catch((error) => {


            });



        this.setState({sitewide_rankings: this.state.sitewide_rankings.filter(function(page) {

            return page.id !== ds.state.current_page.id

        })}, function () {

            if (this.state.sitewide_rankings.length){
                this.setState({current_page: this.state.sitewide_rankings[0]})
            } else{
                this.newPage();
            }
        });

    }

    


    render() {



        return (

            <div>
                <div className="report-block">

                    {
                        !this.state.reports_fetched &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }


                    {
                        this.state.reports_fetched &&

                        <div className="report-block-content">
                            <div className="report-block-header with-highlights" style={{display:'block',position:'relative'}}>
                                <div className="">
                                    <h2>Keyword Tracking</h2>
                                    <div className="description">{ moment(this.state.current_report_year_month+'-01', "YYYY-MM-DD").format("MMMM  YYYY") }</div>
                                </div>

                                <div className="page-description--highlights">

                                    <ReportsPageDescription client_slug={this.props.client_slug} page="keyword-tracking" current_user={this.state.current_user}/>

                                    <div className="page-highlights-wrap">
                                        <div className="page-highlights col4">

                                        </div>
                                    </div>

                                </div>
                                
                            </div>

                            
                            {
                                this.state.current_page.id > 0 &&
                                <div>
                                    <iframe scrolling="no"  src={ process.env.REACT_APP_URL + '/?action=d6-spreadsheet-keyword-tracking&client='+this.state.client_slug+'&id='+this.state.current_page.id+'&report_year_month='+this.state.current_report_year_month} title="Keyword Tracking" className="d6-spreadsheet" frameBorder="0" style={{'height': '877px'}}></iframe>
                                </div>
                            }


                        </div>

                    }



                </div>

                
                
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsKeywordTracking);