import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import '../assets/styles/Header.scss';

import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class MobileNav extends Component {

    constructor(props){
        super(props);

        this.state = {
            client: {

            },

            current_user:{
                photo: '',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : ''
        }

        this.forceRerender = this.forceRerender.bind(this);

    }

    componentDidMount(){

        this.setState({current_user: this.props.current_user});


        if (this.props.current_user.first_name){
            this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
        }

        window.$j(document).on('click','.backdrop, .mobile-nav a', function () {
            window.$j('.mobile-nav').removeClass('open');
        });


        window.$j(document).on('click','.breadcrumbs', function (e) {
            window.$j('.mobile-nav').addClass('open');
            e.preventDefault();
            return false;
        });


    }


    componentWillReceiveProps(nextProps) {

        this.setState({client: nextProps.client});

        //this.setState({current_user: nextProps.current_user});
        //this.setState({ data: nextProps.data });

    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }

    render() {



        if (this.state.current_user.wp_user_id){


            if (this.state.current_user.role === 'client'){


                return (

                    <div className="mobile-nav">

                        <div className="backdrop"></div>

                        <div className="mobile-nav-wrap">

                            <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                            <div className="logged-user">
                                <div>Hello, <Link to={'/' + this.state.current_user.slug + '/profile'}><span className="name">{this.state.current_user.first_name}</span></Link></div>
                                <div><Link to={'/logout'}><span>Logout</span></Link></div>

                                <Link to={'/' + this.state.current_user.slug + '/profile'}>
                                    <div className="photo">

                                        {
                                            (this.state.current_user.photo)
                                                ?(<img src={this.state.current_user.photo} alt=""/>)
                                                :(<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                        }

                                    </div>
                                </Link>
                            </div>
                            <nav>

                                <ul className="main-menu">

                                    <li>
                                        <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/' + this.state.current_user.slug + '/requests'}><span><i className="fas fa-code"></i> Website Support</span></NavLink>
                                    </li>

                                    <li>
                                        <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/' + this.state.current_user.slug + '/profile'}><span><i className="fas fa-user-tie"></i> Profile</span></NavLink>
                                    </li>

                                    {/*<li>*/}
                                        {/*<NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/availability'}><span><i className="far fa-calendar-alt"></i> D6 Availability</span></NavLink>*/}
                                    {/*</li>*/}


                                </ul>

                                <Link to={'/' + this.state.current_user.slug + '/request-support'} className="btn btn-primary btn-sm"><span>Request Work</span></Link>

                            </nav>
                        </div>
                    </div>

                )


            }else {


                return (

                    <div className="mobile-nav">

                        <div className="backdrop"></div>

                        <div className="mobile-nav-wrap">

                            <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                            <div className="logged-user">
                                <div>Hello, <a href="/wp-admin/"><span className="name">{this.state.current_user.first_name}</span></a>
                                </div>
                                <div><Link to={'/logout'}><span>Logout</span></Link></div>

                                <a href="/wp-admin/">
                                    <div className="photo">

                                        {
                                            (this.state.current_user.photo)
                                                ? (<img src={this.state.current_user.photo} alt=""/>)
                                                : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                        }

                                    </div>
                                </a>
                            </div>
                            <nav>
                                <ul className="main-menu">
                                    <li>
                                        <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/admin'}><span><i className="fas fa-code"></i> Requests</span></NavLink>
                                    </li>
                                    {/*<li>*/}
                                        {/*<NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/availability'}><span><i className="far fa-calendar-alt"></i> D6 Availability</span></NavLink>*/}
                                    {/*</li>*/}
                                </ul>
                            </nav>
                        </div>
                    </div>

                )

            }



        }


        else{



            return (

                <div className="mobile-nav">

                    <div className="backdrop"></div>

                    <div className="mobile-nav-wrap">

                        <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                        <div className="login-text">
                            <div>Existing D6 Support customer?<br /><Link to={'/login'}><span>Sign-in</span></Link></div>
                        </div>
                        <nav>

                            <Link to={'/request'} className="btn btn-primary btn-sm"><span>Request Work</span></Link>

                            {/*<Link to={'/sign-up'} className="btn btn-primary btn-sm"><span>Sign-up</span></Link>*/}
                        </nav>
                    </div>
                </div>

            )

        }


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);