import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import '../assets/styles/Header.scss';

import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class MobileNav extends Component {

    constructor(props){
        super(props);

        this.state = {
            client: {
                portal_first_page_url:'/',
                portal_first_report_url:'/',
                projects_url: '/',
                is_seo_client: "0"
            },

            info_fetched: false,

            current_user:{
                photo: '',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : ''
        }

        this.forceRerender = this.forceRerender.bind(this);

    }

    componentDidMount(){


        

        this.setState({
            current_user: this.props.current_user,
            client: this.props.client
        });

        //console.log(this.props.client)


        if (this.props.current_user.first_name){
            this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
        }

        window.$j(document).on('click','.backdrop, .mobile-nav a', function () {
            window.$j('.mobile-nav').removeClass('open');
        });


        window.$j(document).on('click','.breadcrumbs', function (e) {
            window.$j('.mobile-nav').addClass('open');
            e.preventDefault();
            return false;
        });


    }


    componentWillReceiveProps(nextProps) {


        //console.log(nextProps.client);

        this.setState({client: nextProps.client});

        //this.setState({current_user: nextProps.current_user});
        //this.setState({ data: nextProps.data });

    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }

    render() {









            return (

                <div className="mobile-nav">

                    <div className="backdrop"></div>

                    <div className="mobile-nav-wrap">

                        <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                        <div className="logged-user">
                            <div>Hello, <Link to={'/login'}>


                                {
                                    (this.state.client && this.state.client.company) &&

                                    <span className="name">{this.state.client.company}</span>
                                }

                            </Link></div>


                            <div><Link to={'/login'}><span>Sign-In</span></Link></div>

                            <a href="/wp-admin/">
                                <div className="photo">

                                    {
                                        (this.state.client && this.state.client.photo)
                                            ? (<img src={this.state.client.photo} alt=""/>)
                                            : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                    }

                                </div>
                            </a>
                        </div>

                        <nav>
                            <ul className="main-menu">

                                <li>
                                    <NavLink to={'/make-request/'}><span>Make a Request</span></NavLink>
                                </li>

                            </ul>


                            {/*<Link to={'/login/'} className="btn btn-primary btn-sm"><span>Make a Request</span></Link>*/}

                        </nav>
                    </div>
                </div>

            )

        }


}


export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);