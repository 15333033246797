import axios from 'axios';


export function authHeader() {


    if (JSON.parse(localStorage.getItem('currentUser')).token){
        return {
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token
            }
        };
    }

    else{

        return {}
    }

}


export function convertServerDateToLocal(date) {

    // EST - UTC offset: 5 hours
    var offset = 5.0,

        /*
         - calculate the difference between the server date and UTC
         - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
         - the time-zone offset is the difference, in minutes, between UTC and local time
         - 60000 milliseconds = 60 seconds = 1 minute
         */
        serverDate = new Date(date),
        utc = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000),

        /*
         - apply the offset between UTC and EST (5 hours)
         - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
         */
        clientDate = new Date(utc + (3600000 * offset));

    return clientDate.toLocaleString();
}

export function getPage(page_id) {



    return axios.get(process.env.REACT_APP_API_ENDPOINT + '/get-page/' + page_id)
        .then(res => {

            return res;

        })

        .catch((error) => {

            return error.response;

        });


}



export function makeKey(length) {

    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}


export function makeSlug(Text) {

    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'');
}

export function permissionCan(perm, user_role, perms) {



    if( (perm === 'edit' || perm === 'delete') && (user_role === 'administrator' || user_role === 'staff') ) {
        return true;
    }


    for (let i in perms) {

        if (
            (perm === 'edit' && perms[i].perm === 'admin_only_edit' && (user_role !== 'administrator' || user_role !== 'staff')) ||
            (perm === 'delete' && perms[i].perm === 'admin_only_delete' && (user_role !== 'administrator' || user_role !== 'staff'))
        ){

            if(perms[i].value === 1){
                return false;
            }else{
                return true;
            }
            

        } else if (perm === 'upload' && perms[i].perm === 'allow_upload_attachment'){

            if(perms[i].value === 1){
                return true;
            }else{
                return false;
            }
        }

    }


}