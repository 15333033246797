import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";


//import Header from './partials/header'
import Header from '../header-v2'
import BlogPostTracker from './main'
// import Home from './home'
import './style.scss';

const mapStateToProps = state => {
    return { ...state };
};



function mapDispatchToProps(dispatch) {
    return {

    };
}


class BlogPostTrackerHome extends Component {

    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);

    }

    componentDidMount(){

        window.$j('body, html').addClass('blog-post-tracker');

    }

    componentWillReceiveProps(nextProps) {

        window.$j('body, html').removeClass('blog-post-tracker');

    }

    render() {

        return (

            <div className="page-wrap project-book-page-wrap">

                <div>

                    <Header />

                    <main id="blog-post-tracker-main">
                        <Switch>
                            
                            <Route path="/blog-post-tracker/:client_slug/item/:blogPostID" component={BlogPostTracker}/>
                            <Route path="/blog-post-tracker/:client_slug/*" component={BlogPostTracker}/>
                            <Route path="/blog-post-tracker/:client_slug" component={BlogPostTracker}/>
                            <Route path="/blog-post-tracker/" component={BlogPostTracker}/>
                        </Switch>
                     
                    </main>
                </div>

            </div>



        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlogPostTrackerHome));