import React, { Component } from "react";

import { authHeader } from '../../helpers/helpers'

import axios from 'axios';



var ajax_delay = (function(){
    var ajax_timer = 0;
    return function(callback, ms){
        clearTimeout (ajax_timer);
        ajax_timer = setTimeout(callback, ms);
    };
})();



class ReportPageDescription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            client_slug: '',
            page: '',
            current_user:{},
            description: '',
            description_placeholder: 'Add description here',
            enableContentEdit: false
        };

        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleOnInput       = this.handleOnInput.bind(this);
        this.handleBlur       = this.handleBlur.bind(this);

    }


    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        var client = this.state.client;
        client[name] = value

        this.setState({client});
    }


    handleSubmit(event) {



        var description = window.$j('#sec-'+ this.state.page).html();//event.currentTarget.textContent;


        this.setState({btnEnabled: false});


        if (window.$j('#sec-'+ this.state.page).text() === this.state.description_placeholder){
            description = '';
        }



        let formData = new FormData();
        formData.append('client', this.state.client_slug);
        formData.append('page', this.state.page);
        formData.append('description', description);



        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/update-report-page-description', formData , authHeader())
            .then(res => {

                //console.log(res);
                //console.log(res.data);

                if (res.data.success){

                    this.setState({
                        msg: res.data.msg,
                        errorMsg: '',
                    });


                }else {

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({btnEnabled: true});

            })

            .catch((error) => {

                this.setState({btnEnabled: true});
                //console.log(error.response);
                //this.setState({errorMsg: error.response.data.message});

            });

    }


    getReportPageDescription(props){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/report-page-description/?page='+this.state.page )
            .then(res => {


                let description = res.data.description;


                if (description){
                    this.setState({
                        description: description ,
                    })
                }else{


                    if (this.state.current_user && this.state.current_user.role === 'administrator'){
                        this.setState({
                            description: this.state.description_placeholder ,
                        })
                    }

                }

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }


    setData(props){

        this.setState({
            client_slug     : props.client_slug,
            page            : props.page,
            current_user    : props.current_user
        },function () {
            this.getReportPageDescription(props);
        });


        
    }

    componentDidMount(){

        this.setData(this.props);

        var ds = this;

        window.$j('body').on('focus', '#sec-'+ this.props.page, function () {
            if ( window.$j(this).text() === ds.state.description_placeholder){
                ds.setState({description: ''})
            }
        });

    }



    componentDidUpdate(prevProps){

        if(JSON.stringify(this.props) !== JSON.stringify(prevProps)){
            this.setData(this.props);
        }
       
    }

    handleOnInput(e){

        var ds = this;
        ajax_delay(function(){
            ds.handleSubmit(e);
        }, 1000 );

    }


    handleBlur(){


        if (window.$j('#sec-'+ this.state.page).text() === ''){
            this.setState({description: this.state.description_placeholder})
        }

        this.setState({enableContentEdit: false});

        this.handleSubmit();
    }


    enableContentEdit(){

        this.setState({enableContentEdit: true}, function () {
            window.$j('#sec-'+ this.state.page).focus();
        });

    }

    render() {



        return (
            <div>

                {
                    ( this.state.current_user && this.state.current_user.role === 'administrator' )
                        &&
                        <div className={'page-description ' + this.state.page + '-desc'}>
                            <div id={'sec-'+this.state.page} className="editable page-description-field" dangerouslySetInnerHTML={{__html: this.state.description}} onDoubleClick={()=>this.enableContentEdit()} contentEditable={this.state.enableContentEdit} onInput={this.handleOnInput} onBlur={this.handleBlur}></div>
                        </div>

                }


                {
                    ( this.state.current_user.role !== 'administrator' && this.state.description !== '') &&

                        <div className={'page-description ' + this.state.page + '-desc'}>
                            <div id={'sec-'+this.state.page} className="page-description-field" dangerouslySetInnerHTML={{__html: this.state.description}}></div>
                        </div>
                }

            </div>
        );
    }
}





export default ReportPageDescription;

