import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';
//import SweetAlert from 'sweetalert-react';
//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';

import ReportsPageDescription from './partials/report-page-description';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';
//import moment from 'moment';
//import Dropzone from 'react-dropzone';
//import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ReportsFormLeads extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnEnabled: true,
            current_user: {},
            client_slug: '',
            reports_fetched: false,
            current_report_year_month: '',
            lead_forms: [],
            
        };



        //this.newPage                     = this.newPage.bind(this);
    }


    getReportDetails(props){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/form-leads/?report_year_month='+this.state.current_report_year_month )
            .then(res => {


                let lead_forms = res.data.lead_forms;
                let highlights = res.data.highlights;


                this.setState({
                    reports_fetched: true,
                    lead_forms: lead_forms ,
                    highlights: highlights
                }, function () {


                    //for new page
                    this.setState({
                        kps_chart_render: false
                    });

                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){



        this.setState({
            current_report_year_month   : this.props.current_report_year_month,
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug
        },function () {
            this.getReportDetails(this.props);
        });


    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }



    render() {



        return (


            <div>
                <div className="report-block">

                    {
                        !this.state.reports_fetched &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }

                    {
                        this.state.reports_fetched &&

                        <div className="report-block-content">
                            <div className="report-block-header with-highlights" style={{display:'block',position:'relative'}}>
                                <div className="">
                                    <h2>Form Leads</h2>
                                </div>

                                <div className="page-description--highlights">

                                    <ReportsPageDescription client_slug={this.props.client_slug} page="form-leads" current_user={this.state.current_user}/>

                                    <div className="page-highlights-wrap">
                                        <div className="page-highlights">

                                            {
                                                this.state.highlights.highlight1.cnt > 0 &&
                                                <div className="page-highlight-item green">
                                                    <div className="page-highlight-item-inner">
                                                        <div className="icon" dangerouslySetInnerHTML={{__html: this.state.highlights.highlight1.icon}}></div>
                                                        <h4>{parseInt(this.state.highlights.highlight1.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.highlights.highlight1.text}}></span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                this.state.highlights.highlight2.cnt > 0 &&
                                                <div className="page-highlight-item purple">
                                                    <div className="page-highlight-item-inner">
                                                        <div className="icon" dangerouslySetInnerHTML={{__html: this.state.highlights.highlight2.icon}}></div>
                                                        <h4>{parseInt(this.state.highlights.highlight2.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.highlights.highlight2.text}}></span>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {
                                                this.state.highlights.highlight3.cnt > 0 &&
                                                
                                                <div className="page-highlight-item blue">
                                                    <div className="page-highlight-item-inner">
                                                        <div className="icon" dangerouslySetInnerHTML={{__html: this.state.highlights.highlight3.icon}}></div>
                                                        <h4 dangerouslySetInnerHTML={{__html: this.state.highlights.highlight3.cnt_txt}}></h4><span dangerouslySetInnerHTML={{__html: this.state.highlights.highlight3.text}}></span>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                this.state.highlights.highlight4.cnt > 0 &&
                                                
                                                <div className="page-highlight-item orange">
                                                    <div className="page-highlight-item-inner">
                                                        <div className="icon" dangerouslySetInnerHTML={{__html: this.state.highlights.highlight4.icon}}></div>
                                                        <h4>{parseInt(this.state.highlights.highlight4.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.highlights.highlight4.text}}></span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                            {
                                this.state.lead_forms.map((form,i) =>
                                    <div key={i} style={{marginBottom:'20px'}}>
                                        <iframe scrolling="no" src={ process.env.REACT_APP_URL + '/?action=d6-spreadsheet-form-leads&form='+form+'&client='+this.state.client_slug+'&report_year_month='+this.state.current_report_year_month} title="Form Leads" className="d6-spreadsheet-form-leads" id="form-leads-iframe" frameBorder="0" style={{'height': '877px'}}></iframe>
                                    </div>
                                )
                            }
                        </div>
                    }

                </div>



            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsFormLeads);