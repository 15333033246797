import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from 'axios';
import './assets/styles/App.scss';

import { connect } from "react-redux";
import { setCurrentUser, setSiteDetails } from "./redux-js/actions/index";

import Home from './components/hourly-support/home'
import Login from './components/hourly-support/login';
import LostPassword from './components/hourly-support/lost-password';
import Logout from './components/hourly-support/logout';

//import SignUp from './components/hourly-support/signup';
//import SupportPlansPage from './components/hourly-support/support-plans';
//import HowItWorksPage from './components/hourly-support/how-it-works';
//import RequestSupportHourly from './components/hourly-support/request-support-hourly';




import Page from './components/page';

import AvailabilityPage from './components/hourly-support/availability';

//import MobileNav from './components/mobile-nav';
import MakeAPayment from './components/hourly-support/make-a-payment';
import Card from './components/hourly-support/card';

//import ProjectApprovalForm from './components/hourly-support/project-approval-form';
//import Projects from './components/hourly-support/projects';

import Admin from './components/admin/admin';
import AdminProjectBooks from './components/admin/project-books';

import page404 from './components/404';
//import D6Flex from './components/d6-flex/main';
import BlogPostTracker from './components/blog-post-tracker/index';

//PROJECT BOOK
import ProjectBookMain from './components/project-book/main'


//import jquery from 'jquery';
//window.$j = window.jQuery = jquery;
import MakeRequest from './components/hourly-support/make-request-public'
import singleRequestPublic from './components/hourly-support/single-request-public';

// Add a request interceptor
axios.interceptors.request.use((config) => {
    // Do something before request is sent
    return config;
}, (error) => {

    //console.log(error);
    // Do something with request error
    return Promise.reject(error);
});


// Add a response interceptor
axios.interceptors.response.use((response) => {

    //console.log('response',response)
    // Do something with response data
    return response;

}, (error) => {

    if (error.response.data.data.status === 403){

        //window.location.href = '/login';

    }else{

        //window.location.href = '/';
    }

    //console.log(error.response);

    // Do something with response error
    return Promise.reject(error);
});



const mapStateToProps = state => {
    return { ...state };
};





function mapDispatchToProps(dispatch) {
    return {
        setCurrentUser: currentUser => dispatch(setCurrentUser(currentUser)),
        setSiteDetails:  details => dispatch(setSiteDetails(details))
    };
}


class App extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_user_fetched: false,
            site_details_fetched: false,
            current_user: {}
        }
    }



    componentWillUnmount() {

        //this.unlisten();
    }


    componentDidMount(){


        //console.log(React.version);


        if ( !JSON.parse(localStorage.getItem('currentUser')) ) {

            //IMPORT to initialize token
            //otherwise API queries will return errors, token undefined
            localStorage.setItem('currentUser', JSON.stringify({token:''}));

        }



        axios.get(process.env.REACT_APP_API_ENDPOINT + '/details')
            .then(res => {

                this.props.setSiteDetails(res.data.details);

                //this.setState({current_user: res.data.user})

                this.setState({site_details_fetched: true});

            })

            .catch((error) => {

                this.props.setSiteDetails({});
                this.setState({site_details_fetched: true});

                //console.log(error.config);
            });



        if (JSON.parse(localStorage.getItem('currentUser')).token) {

            let headers = {headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token}};

            axios.get(process.env.REACT_APP_API_ENDPOINT + '/current-user', headers)
                .then(res => {

                    this.props.setCurrentUser(res.data.user);

                    //this.setState({current_user: res.data.user})

                    this.setState({current_user_fetched: true});

                })

                .catch((error) => {

                    localStorage.setItem('currentUser', JSON.stringify({}));
                    //console.log(error.response.data);
                    this.props.setCurrentUser({});
                    this.setState({current_user_fetched: true});

                    //console.log(error.config);
                });

        }else{


            this.setState({current_user_fetched: true});

        }


        window.$j('body').css({'min-height':window.$j(window).height() + 1});


        window.$j(window).resize(function () {
            window.$j('body').css({'min-height':window.$j(window).height() + 1});
            window.$j('main').css({'height':window.$j(window).height() -70});
        });


       
        
    }


    runJS(){

        setTimeout(()=>{

            window.$j('.head-notice').css({'max-height': '1000px'});


        },3000)
    }

    render() {

        this.runJS();

        if (!this.state.current_user_fetched || !this.state.site_details_fetched){
            //we will make sure we get the current user first
            return null;
        }


        return (
          <div className="App">
              <div className='head-notice'><div style={{padding: '10px', textAlign:'center', fontSize: '13px', background: '#cf2940', color: '#FFF'}}>All client reporting & information has been migrated & fully available in the new D6 Portal. <a style={{borderBottom: '1px dashed #FFF', color: '#00e0ff', textDecoration: 'none'}} href="https://portal.d6support.com/">Click here</a> & access your information by logging in.</div></div>
              <Router>

                      <div>


                          <Switch>
                              {/*<Route exact path="/login" component={Login}/>*/}
                              {/*<Route exact path="/sign-up" component={SignUp}/>*/}
                              {/*<Route path="" component={Home}/>*/}



                              <Route exact path="/404" component={page404}/>
                              <Route exact path="/login" component={Login}/>
                              <Route exact path="/login/:login_as" component={Login}/>
                              <Route exact path="/lost-password" component={LostPassword}/>
                              <Route exact path="/logout" component={Logout}/>
                              {/*<Route exact path="/sign-up" component={SignUp}/>*/}
                              {/*<Route path="/request" component={RequestSupportHourly}/>*/}
                              {/*<Route path="/support-plans" component={SupportPlansPage}/>*/}
                              {/*<Route path="/how-it-works" component={HowItWorksPage}/>*/}
                              <Route path="/availability" component={AvailabilityPage}/>
                              <Route path="/pages/:page_slug" component={Page}/>
                              <Route path="/make-a-payment" component={MakeAPayment}/>
                              <Route path="/card/:client" component={Card}/>

                              {/*<Route path="/projects/:client_slug/:status" component={Projects}/>*/}
                              {/*<Route path="/projects/:client_slug" component={Projects}/>*/}
                              {/*<Route path="/projects/" component={Projects}/>*/}
                              {/*<Route path="/project/:project_scope_slug" component={ProjectApprovalForm}/>*/}


                              <Route path="/make-request" component={MakeRequest}/>
                              <Route path="/request/:request_long_id" component={singleRequestPublic}/>

                              <Route path="/portal" component={ProjectBookMain}/>

                              <Route exact path="/admin/portals/" component={AdminProjectBooks}/>
                              <Route exact path="/admin/" component={Admin}/>


                              {/* <Route path="/d6-flex" component={D6Flex}/> */}

                              
                              <Route path="/blog-post-tracker" component={BlogPostTracker}/>
                              


                              <Route path="" component={Home}/>

                              {/*<Route path="" render={(props) => (<Home current_user={this.state.current_user} {...props}/>)} />*/}

                          </Switch>

                      </div>

              </Router>

          </div>
        );
  }
}




// We need an intermediary variable for handling the recursive nesting.
//const AppWrapped = withStyles()(App);

export default connect(mapStateToProps, mapDispatchToProps)(App);