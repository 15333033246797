import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';
import SweetAlert from 'sweetalert-react';
//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';

import ReportsPageDescription from './partials/report-page-description';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';
//import moment from 'moment';
//import Dropzone from 'react-dropzone';
import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ReportsPageOrganicKeywords extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnEnabled: true,
            current_user: {},
            client_slug: '',
            delete_confirm: false,
            current_page: {
                id: 0,
                title: '',
                description: '',
                url: '',
                hide_previous_position_change: 0,
            },
            is_sort_pages:false,
            organic_keywords_pages: [],
            organic_keywords_pages_highlights: [],
            keywords_position_distribution_chart: [],
            reports_fetched: false,
            show_pages_dropdown: false,
            current_report_year_month: '',
            kps_chart_render: false

        };



        this.newPage                    = this.newPage.bind(this);
        this.handleInputChange          = this.handleInputChange.bind(this);
        this.handleSubmit               = this.handleSubmit.bind(this);
        this.showHidePrevPosChangeColumn               = this.showHidePrevPosChangeColumn.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // this.setState({
        //     [name]: value
        // });

        this.setState({
            current_page: {...this.state.current_page, [name]: value}
        })

    }


    handleSubmit(event) {


        event.preventDefault();

        this.setState({'btnEnabled': false});


        let formData = new FormData();



        formData.append('client', this.state.client_slug);
        formData.append('title', this.state.current_page.title);
        formData.append('url', this.state.current_page.url);


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/add-organic-keywords-page-v2?report_year_month='+this.state.current_report_year_month, formData , authHeader())
            .then(res => {


                if (res.data.errors){

                    alert(res.data.errors)

                }else {

                    this.setState({
                        //organic_keywords_pages: this.state.organic_keywords_pages.concat(res.data.new_page),
                        current_page: res.data.new_page,
                    },function () {

                        this.getReportDetails(this.props);



                    });

                }

                this.setState({'btnEnabled': true});
            })

            .catch((error) => {

                //console.log(error.response.data);
                this.setState({
                    errorMsg: error.response.data.message
                });
                this.setState({'btnEnabled': true});

            });


    }




    getReportDetails(props){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/page-organic-keywords-v2/?report_year_month='+this.state.current_report_year_month )
            .then(res => {


                let organic_keywords_pages = res.data.organic_keywords_pages;
                let organic_keywords_pages_highlights = res.data.organic_keywords_pages_highlights;
                let keywords_position_distribution_chart = res.data.keywords_position_distribution_chart;




                if (organic_keywords_pages.length > 0){

                    if (this.state.current_page && this.state.current_page.id){

                        for(let x = 0; x < organic_keywords_pages.length; x++ ){

                            if (this.state.current_page.id === organic_keywords_pages['id']){

                                this.setState({
                                    current_page: organic_keywords_pages[x],
                                });

                                break;
                            }
                        }

                    }else{
                        this.setState({
                            current_page: organic_keywords_pages[0],
                        });
                    }


                }

                this.setState({
                    reports_fetched: true,
                    organic_keywords_pages: organic_keywords_pages ,
                    organic_keywords_pages_highlights: organic_keywords_pages_highlights,
                    keywords_position_distribution_chart: keywords_position_distribution_chart
                }, function () {


                    //for new page
                    this.setState({
                        kps_chart_render: false
                    });

                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){


        let ds = this;

        this.setState({
            current_report_year_month   : this.props.current_report_year_month,
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug
        },function () {
            this.getReportDetails(this.props);
        });





        let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        let eventer = window[eventMethod];
        let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        // Listen to message from child window
        eventer(messageEvent,function(e) {

            if(e.data === 'reload-organic-keyword-pages'){
                ds.getReportDetails(ds.props)
            }

        },false);


        window.$j(document).on('click', function (e) {

            if( !window.$j(e.target).closest('.reports-keyword-ranking-pages').length ){
                ds.setState({
                    show_pages_dropdown: false
                })
            }
        })
    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }


    setCurrentPage(page){


        if(this.state.is_sort_pages) return false;


        this.setState({
            current_page: page,
            kps_chart_render: false,
            show_pages_dropdown: !this.state.show_pages_dropdown
        });

    }

    newPage(){

        this.setState({
            current_page: {id: 0, title: 'New Page', spreadsheet_key: '', description: ''},
            show_pages_dropdown: !this.state.show_pages_dropdown
        });

    }

    handleSortKeywordRankingPages(pages){


        let open_pages = [];

        window.$j('.project-book-sidebar li.show-items').each(function () {
            if (window.$j(this).data('id')){
                open_pages.push(window.$j(this).data('id'));
            }
        });


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/sort-organic-keywords-pages', { pages: pages }, authHeader())
            .then(res => {



            })

            .catch((error) => {

            });

    }

    sortPages(){

        let that = this;

        this.setState({
            is_sort_pages: true,
        });


        if (typeof(window.$j('.reports-keyword-ranking-pages-list').nestedSortable) !== "function"){
            return;
        }

        window.$j('#reports-keyword-ranking-pages-list').nestedSortable({
            handle: '.list-item-wrap',
            listType:'ul',
            items: 'li.list-item',
            //toleranceElement: '> div.item-wrap',
            protectRoot: false,
            disableParentChange: false,
            //isTree: false,
            maxLevels: 1,
            distance: 10,

            update: function( event, ui ) {

                let pages = [];
                let x = 0;


                window.$j('ul#reports-keyword-ranking-pages-list>li').each(function () {

                    pages.push({
                        id: window.$j(this).data('id'),
                        order: x,
                    });
                    x++;
                });

                that.handleSortKeywordRankingPages(pages);

            }
        });

    }

    doneSort(){

        this.setState({
            is_sort_pages: false,
        });


        window.$j( '#reports-keyword-ranking-pages-list' ).nestedSortable( 'destroy' );
    }

    deleteConfirmed(){

        var ds = this;


        let formData = new FormData();

        formData.append('id', this.state.current_page.id);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/delete-organic-keywords-page-v2', formData, authHeader())
            .then(res => {



            }).catch((error) => {



            });



        this.setState({organic_keywords_pages: this.state.organic_keywords_pages.filter(function(page) {

            return page.id !== ds.state.current_page.id

        })}, function () {

            if (this.state.organic_keywords_pages.length){
                this.setState({current_page: this.state.organic_keywords_pages[0]})
            } else{
                this.newPage();
            }
        });

    }

    showHidePrevPosChangeColumn(id,val){


        let formData = new FormData();

        formData.append('id', id);
        formData.append('val', val);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/show-hide-organic-keywords-page-column', formData, authHeader())
            .then(res => {

                window.$j( 'iframe#keyword-ranking-iframe' ).attr( 'src', function ( i, val ) { return val; });

            }).catch((error) => {

        });



        let current_page = this.state.current_page;
        current_page.hide_previous_position_change = val;
        this.setState({current_page: current_page});

        //this.state.organic_keywords_pages[0];

    }


    renderKeywordsPositionDistributionChart(id){


        if(id){


            if ( window.$j('#keywords-position-distribution-chart').length && !this.state.kps_chart_render ) {

                this.setState({kps_chart_render: true});


                window.Highcharts.chart('keywords-position-distribution-chart', {

                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },

                    credits: {
                        enabled: false
                    },

                    subtitle: {
                        text: ''
                    },

                    yAxis: {
                        min: 0,

                        stackLabels: {
                            enabled: true,
                        },
                        title: {
                            text: 'Keywords'
                        }
                    },
                    xAxis: {
                        categories: this.state.keywords_position_distribution_chart[id].categories,

                    },


                    legend: {
                        enabled: false
                    },

                    tooltip: {
                        formatter: function () {
                            return 'Position in SERP: ' + this.x + '<br/>' +
                                'Number of keywords: '+window.Highcharts.numberFormat(this.y, 0, '.', ',') ;
                        }
                    },

                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        }
                    },

                    series: [this.state.keywords_position_distribution_chart[id].series]

                });



            } else{

                var ds = this;
                setTimeout(function () {
                    ds.renderKeywordsPositionDistributionChart(id);
                },1000)
            }

        }

    }


    reportSparkLine(){



        /**
         * Create a constructor for sparklines that takes some sensible defaults and merges in the individual
         * chart options. This function is also available from the jQuery plugin as $(element).highcharts('SparkLine').
         */
        window.Highcharts.SparkLine = function (a, b, c) {
            const hasRenderToArg = typeof a === 'string' || a.nodeName;
            let options = arguments[hasRenderToArg ? 1 : 0];
            const defaultOptions = {
                chart: {
                    renderTo: (options.chart && options.chart.renderTo) || (hasRenderToArg && a),
                    backgroundColor: null,
                    borderWidth: 0,
                    type: 'column',
                    margin: [2, 0, 2, 0],
                    //width: 140,
                    height: 50,
                    style: {
                        overflow: 'visible'
                    },
                    // small optimalization, saves 1-2 ms each sparkline
                    skipClone: true
                },

                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPositions: []
                },
                yAxis: {
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    tickPositions: [0]
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    hideDelay: 0,
                    outside: true,
                    shared: true
                },
                plotOptions: {
                    series: {
                        animation: false,
                        lineWidth: 1,
                        //color: '#37c3ff',
                        shadow: false,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        borderWidth: 0,
                        marker: {
                            radius: 1,
                            states: {
                                hover: {
                                    radius: 2
                                }
                            }
                        },
                        fillOpacity: 0.25
                    },
                    column: {
                        negativeColor: '#910000',
                        borderColor: 'silver'
                    }
                }
            };

            options = window.Highcharts.merge(defaultOptions, options);

            return hasRenderToArg ?
                new window.Highcharts.Chart(a, options, c) :
                new window.Highcharts.Chart(options, b);
        };

        const
            sparkLineDivs = Array.from(document.querySelectorAll('div[data-sparkline]')),
            fullLen = sparkLineDivs.length;

        let n = 0;

        // Creating 153 sparkline charts is quite fast in modern browsers, but IE8 and mobile
        // can take some seconds, so we split the input into chunks and apply them in timeouts
        // in order avoid locking up the browser process and allow interaction.


        function doChunk() {

            const time = +new Date(),
                len = sparkLineDivs.length;

            for (let i = 0; i < len; i += 1) {
                const div = sparkLineDivs[i];
                const stringdata = div.dataset.sparkline;
                const arr = stringdata.split('; ');
                const data = arr[0].split(', ').map(parseFloat);
                const chart = {};



                if (arr[1]) {
                    chart.type = arr[1];
                }

                window.Highcharts.SparkLine(div, {
                    series: [{
                        data: data,
                        pointStart: 1
                    }],
                    tooltip: {
                        formatter: function(){
                            //console.log();
                            return '<span style="font-size: 10px">'+ div.dataset.dates.split(",")[this.x-1]+':</span><br/><b>'+this.y+'</b> Pageviews'
                        }


                        //headerFormat: '<span style="font-size: 10px">Week ' + div.dataset.dates.split(",")[{point.x}]+':</span><br/>',
                        //pointFormat: '<b>{point.y}</b> Sessions'
                    },
                    chart: chart
                });

                n += 1;

                // If the process takes too much time, run a timeout to allow interaction with the browser
                if (new Date() - time > 500) {
                    sparkLineDivs.splice(0, i + 1);
                    setTimeout(doChunk, 0);
                    break;
                }

                // Print a feedback on the performance
                if (n === fullLen) {
                    //document.getElementById('result').innerHTML = 'Generated ' + fullLen + ' sparklines in ' + (new Date() - start) + ' ms';
                }
            }
        }
        doChunk();


    }

    render() {



        return (


            <div>
                <div className="report-block">

                    {
                        !this.state.reports_fetched &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }


                    {
                        this.state.reports_fetched &&

                        <div className="report-block-content">
                            <div className="report-block-header" style={{display: 'block'}}>
                                <div className="">

                                    <h2>Target Pages Rankings</h2>

                                </div>

                                <div className="reports-keyword-ranking-pages-wrap">

                                    Select Page:

                                    <div className={'reports-keyword-ranking-pages '+ (this.state.is_sort_pages?'sort-page':'')}>

                                        {
                                            this.state.current_page.title !== '' &&
                                            <span className="btn btn-filter"  onClick={()=>this.setState({show_pages_dropdown: !this.state.show_pages_dropdown})}><i className="fa fa-file-alt"></i> {this.state.current_page.title}</span>
                                        }


                                        {
                                            this.state.organic_keywords_pages.length > 0 &&
                                            <ul id="reports-keyword-ranking-pages-list" className={this.state.show_pages_dropdown?'show':''}>
                                                {
                                                    this.state.organic_keywords_pages.map((page,i) =>
                                                        <li data-id={page.id} className={'list-item ' + (this.state.current_page.id === page.id?'active':'')} key={i} onClick={()=>this.setCurrentPage(page)}>
                                                            <div className="list-item-wrap">
                                                                <span className="sort-handle">
                                                                    <i className="fa fa-bars"></i>
                                                                </span>
                                                                {page.title}
                                                            </div>
                                                        </li>
                                                    )
                                                }

                                                {
                                                    this.state.current_user && this.state.current_user.role === 'administrator' &&
                                                    <li className="action">

                                                        {
                                                            !this.state.is_sort_pages &&
                                                            <span onClick={()=>this.newPage()}>Add Page</span>
                                                        }

                                                        {
                                                            !this.state.is_sort_pages &&
                                                            <span onClick={()=>this.sortPages()}>Sort Pages</span>
                                                        }

                                                        {
                                                            this.state.is_sort_pages &&
                                                            <span onClick={()=>this.doneSort()}>Done</span>
                                                        }

                                                    </li>
                                                }

                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>


                            <ReportsPageDescription client_slug={this.props.client_slug} page="organic-keywords" current_user={this.state.current_user}/>


                            {
                                this.state.current_page.id > 0 &&
                                this.state.organic_keywords_pages_highlights &&
                                this.state.organic_keywords_pages_highlights[this.state.current_page.id] &&
                                this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight1 &&
                                this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight1.cnt !== 0 &&


                                <div className="page-highlights-wrap">
                                    <div className="page-highlights">

                                        <div className="page-highlight-item">
                                            <div className="page-highlight-item-inner green">
                                                <div className="icon" dangerouslySetInnerHTML={{__html: this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight1.icon}}></div>
                                                <h4>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight1.cnt}</h4>
                                                <span>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight1.text}</span>
                                            </div>
                                        </div>

                                        <div className="page-highlight-item">
                                            <div className="page-highlight-item-inner purple">
                                                <div className="icon" dangerouslySetInnerHTML={{__html: this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight2.icon}}></div>
                                                <h4>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight2.cnt}</h4>
                                                <span>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight2.text}</span>
                                            </div>
                                        </div>

                                        <div className="page-highlight-item">
                                            <div className="page-highlight-item-inner blue">
                                                <div className="icon" dangerouslySetInnerHTML={{__html: this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight3.icon}}></div>
                                                <h4>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight3.cnt}</h4>
                                                <span>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight3.text}</span>
                                                <div className="organic-keyword-page-sparkline" data-sparkline={this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight3.sparkline} data-dates={this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight3.dates}></div>
                                                {this.reportSparkLine()}
                                            </div>
                                        </div>

                                        <div className="page-highlight-item">
                                            <div className="page-highlight-item-inner orange">
                                                <div className="icon" dangerouslySetInnerHTML={{__html: this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight4.icon}}></div>
                                                <h4>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight4.cnt}</h4>
                                                <span>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight4.text}</span>
                                            </div>
                                        </div>

                                        <div className="page-highlight-item">
                                            <div className="page-highlight-item-inner light-blue">
                                                <div className="icon" dangerouslySetInnerHTML={{__html: this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight5.icon}}></div>
                                                <h4>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight5.cnt}</h4>
                                                <span>{this.state.organic_keywords_pages_highlights[this.state.current_page.id].highlight5.text}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }


                            {
                                this.state.current_page.id > 0 &&



                                <div>


                                    <iframe scrolling="no" src={ process.env.REACT_APP_URL + '/?action=d6-spreadsheet-page-organic-keywords-v2&client='+this.state.client_slug+'&id='+this.state.current_page.id+'&report_year_month='+this.state.current_report_year_month} title="Keyword Ranking" className="d6-spreadsheet" id="keyword-ranking-iframe" frameBorder="0" style={{'height': '877px'}}></iframe>


                                    { this.state.current_user && this.state.current_user.role === 'administrator' &&

                                        <div className="report-block-footer">

                                            <div className="footer-item">

                                            </div>

                                            <button className="btn btn-danger" onClick={()=>this.setState({delete_confirm: true})}><i className="fa fa-trash"></i> Delete This Page</button>

                                            <div className="footer-item">



                                                {
                                                    (this.state.current_page.hide_previous_position_change === 1 || this.state.current_page.hide_previous_position_change === "1")
                                                        ?
                                                        <button className="btn btn-secondary" onClick={()=>this.showHidePrevPosChangeColumn(this.state.current_page.id,0)}><i className="fa fa-check"></i> Show Previous/Position Change Column</button>
                                                        :
                                                        <button className="btn btn-secondary" onClick={()=>this.showHidePrevPosChangeColumn(this.state.current_page.id,1)}><i className="fa fa-times"></i> Hide Previous/Position Change Column</button>

                                                }


                                            </div>
                                        </div>

                                    }

                                </div>
                            }


                            {

                                this.state.current_user && this.state.current_user.role === 'administrator' && this.state.current_page.id === 0 &&
                                <div className="new-organic-keywords-page-form">
                                    <form className="request-form" onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>
                                        <div className="form-group">
                                            <div className="form-label">Page Title</div>
                                            <input className="form-control" type="text" name="title" onChange={this.handleInputChange} value={this.state.current_page.title}/>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-label">Page URL</div>
                                            <input className="form-control" type="text" name="url" onChange={this.handleInputChange} value={this.state.current_page.url}/>
                                        </div>


                                        <div>
                                            <button className="btn btn-primary" disabled={this.state.current_page.title === '' || !this.state.btnEnabled}><span>Submit</span></button>
                                        </div>
                                    </form>
                                </div>
                            }



                        </div>

                    }


                    <SweetAlert
                        show={this.state.delete_confirm}
                        //customClass=""
                        title="Delete?"
                        type="warning"
                        html={true}
                        text="Click <strong>OK</strong> to delete this page."
                        confirmButtonText="OK"
                        showCancelButton
                        onConfirm={() => {
                            this.deleteConfirmed();
                            this.setState({
                                delete_confirm: false
                            });
                        }}
                        onCancel={() => {
                            this.setState({
                                delete_confirm: false
                            });
                        }}

                    />

                </div>


                {
                    this.state.current_page.id > 0 &&

                    <div className="report-block">

                        {
                            !this.state.reports_fetched &&
                            <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                        }


                        {
                            this.state.reports_fetched &&

                            <div className="report-block-content">
                                <div className="report-block-header" style={{display: 'block'}}>
                                    <div className="">

                                        <h2>Keyword Position Distribution</h2>

                                    </div>
                                </div>
                                <div>
                                    <div id="keywords-position-distribution-chart"></div>

                                    {this.renderKeywordsPositionDistributionChart(this.state.current_page.id)}

                                </div>
                            </div>
                        }
                    </div>
                }
            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsPageOrganicKeywords);