import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
//import { authHeader } from '../helpers/helpers'
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';

import moment from 'moment';

//import Dropzone from 'react-dropzone';
//import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}




class ReportsAnalyticsVisitorsStates extends Component {

    constructor(props) {
        super(props);


        let start_date = moment();
        start_date.subtract(1, 'months'); //89 = 3 months
        start_date.startOf('month');
        start_date.toDate();
        start_date = start_date.format('MM/DD/YYYY');

        let end_date = moment();
        end_date.subtract(1, 'month');
        end_date.endOf('month');
        end_date.toDate();
        end_date = end_date.format('MM/DD/YYYY');

        this.state = {
            current_user: {},
            client_slug: '',
            charts_render: false,
            reports: {
            },

            reports_fetched: false,

            start_date: start_date,
            end_date: end_date,
        };


        this.dateFilter      = this.dateFilter.bind(this);
    }


    getReportDetails(props){

        this.setState({
            reports_fetched: false
        })


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/analytics-visitor-states/?start_date='+this.state.start_date+'&end_date='+this.state.end_date )
            .then(res => {

                let reports = res.data.reports;

                this.setState({
                    reports_fetched: true,
                    reports: reports ,
                    charts_render: false,
                }, function () {


                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){

        let start_date = moment(this.props.current_report_year_month+'-01', 'YYYY-MM-DD');
        start_date.startOf('month');
        start_date.toDate();
        start_date = start_date.format('MM/DD/YYYY');


        let end_date = moment(this.props.current_report_year_month+'-01', 'YYYY-MM-DD');
        end_date.endOf('month');
        end_date.toDate();
        end_date = end_date.format('MM/DD/YYYY');


        this.setState({
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug,
            current_report_year_month   : this.props.current_report_year_month,
            start_date: start_date,
            end_date: end_date,

        }, function () {
            this.getReportDetails(this.props);
        });


    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }



    dateFilter(event, picker) {

        this.setState({
            start_date: picker.startDate.format('MM/DD/YYYY'),
            end_date: picker.endDate.format('MM/DD/YYYY'),

        }, function () {

            this.getReportDetails(this.props);
        });

    }

    render() {



        return (


            <div className="report-block">

                <div className="report-block-content">
                    <div className="report-block-header">
                        <div className="">
                            <h2>Top Visitors Filtered By State</h2>
                            <div className="description">{ moment(this.state.start_date, "MM/DD/YYYY").format("MMMM YYYY") }</div>
                        </div>

                        <div className="">

                        </div>

                    </div>

                    {
                        this.state.reports_fetched &&
                        <div>
                            <div className="analytics-table" dangerouslySetInnerHTML={{__html: this.state.reports.html}}></div>
                        </div>
                    }

                    {
                        !this.state.reports_fetched &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }


                </div>





            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsAnalyticsVisitorsStates);