export const ADD_WORK_REQUEST = "ADD_WORK_REQUEST";
export const ADD_COMMENT = "ADD_COMMENT";
export const SET_WORK_REQUEST_SLUG = "SET_WORK_REQUEST_SLUG";
export const SET_PROJECT_BOOK_SLUG = "SET_PROJECT_BOOK_SLUG";
export const SET_PROJECT_BOOK_PAGE_PAGES = "SET_PROJECT_BOOK_PAGE_PAGES";
export const SET_PROJECT_BOOK_INFO = "SET_PROJECT_BOOK_INFO";
export const SET_PROJECT_BOOK_CURRENT_PAGE = "SET_PROJECT_BOOK_CURRENT_PAGE";
export const SET_PAYMENT_MADE = "SET_PAYMENT_MADE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SITE_DETAILS = "SET_SITE_DETAILS";
export const CACHE_CONTENT = "CACHE_CONTENT";
export const SET_HEADER_INFO = "SET_HEADER_INFO";