import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from '../assets/images/d6-support-logo.png';
import '../assets/styles/Header.scss';
import { connect } from "react-redux";
import MobileNav from './mobile-nav-public';
//import store from "../redux-js/store/index";
//import axios from "axios";


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class Header extends Component {

    constructor(props){
        super(props);

        this.state = {

            current_user:{
                company: 'Guest',
                photo: process.env.REACT_APP_URL + '/images/avatar.jpg',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : '',

            show_user_actions_popup: false
        }

        this.forceRerender          = this.forceRerender.bind(this);
        this.toggleUserActionPopup  = this.toggleUserActionPopup.bind(this);
        this.handleClickOutside     = this.handleClickOutside.bind(this);

    }


    componentDidMount(){



        if (this.props.current_user && this.props.current_user.first_name){


            this.setState({current_user: this.props.current_user});

            if (this.props.current_user.first_name){
                this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
            }

        }

        document.addEventListener('mousedown', this.handleClickOutside);

    }


    componentWillUnmount() {

        document.removeEventListener('mousedown', this.handleClickOutside);
    }



    componentWillReceiveProps(nextProps) {



    }


    setCurrentPage(url){



        setTimeout(function () {

            if (url.indexOf('/make-request/') !== -1){

                window.$j('.main-menu .nav-item:not(.nav-make-request)').removeClass('cur');
                window.$j('.main-menu .nav-make-request').addClass('cur');
            }

            else if (url.indexOf('/d6-flex/') !== -1){

                window.$j('.main-menu .nav-item:not(.nav-d6-flex)').removeClass('cur');
                window.$j('.main-menu .nav-d6-flex').addClass('cur');

            }

        },1);
    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }


    handleClickOutside(event) {

        if (! window.$j(event.target).closest('.user-action').length){

            if (this.state.show_user_actions_popup){
                this.setState({show_user_actions_popup: false});
            }

        }
    }

    toggleUserActionPopup(e){

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({show_user_actions_popup: !this.state.show_user_actions_popup});

    }


    render() {



        this.setCurrentPage(window.location.pathname);



        return (

            <div>
                <MobileNav client={this.client} />

                <header className="v2-header">

                    <div className="header-container">
                        <a href="#nav-open" className="breadcrumbs"><span></span></a>
                        <div className="header-wrap">

                            <div className="logo-wrap">
                                <Link to="/login" className="logo"><img src={logo} className="App-logo" alt="logo"/></Link>
                            </div>

                            <div className="logged-user">



                                <div>{this.state.current_user.company}</div>

                                <div className="photo user-action" onClick={this.toggleUserActionPopup}>

                                    {
                                        (this.state.current_user && this.state.current_user.photo) &&

                                        <img src={this.state.current_user.photo} alt=""/>
                                    }


                                </div>

                                <div className={'user-actions'}>
                                    <span className="arrow user-action" onClick={this.toggleUserActionPopup}><i className="fas fa-chevron-down"></i></span>

                                    <div className={'user-action-popup user-action' + ((this.state.show_user_actions_popup)?' show':'')} onClick={this.toggleUserActionPopup}>

                                        {
                                            (this.state.current_user.role === 'administrator' || this.state.current_user.role === 'staff') &&

                                            <ul>
                                                <li><Link to={'/admin/'} className="signout-link"><span>Admin</span></Link></li>
                                                <li><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></li>
                                            </ul>

                                        }

                                        {
                                            this.state.current_user.role === 'client' &&
                                            <ul>
                                                <li><Link to={'/' + this.state.current_user.slug + '/profile'} className="signout-link"><span>Profile</span></Link></li>
                                                <li><Link to={'/' + this.state.current_user.slug + '/billing'} className="signout-link"><span>Billing</span></Link></li>
                                                <li><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></li>
                                            </ul>
                                        }


                                        {
                                            !this.state.current_user.role &&

                                            <ul>
                                                <li><Link to={'/login'} className="signout-link"><span>Sign-in</span></Link>
                                                </li>
                                            </ul>
                                        }

                                    </div>
                                </div>
                            </div>
                            <nav>
                                <ul className="main-menu">

                                    <li>
                                        <NavLink to={'/make-request/'} className={'nav-item nav-make-request'}><span>Make a Request</span></NavLink>
                                    </li>
                                    {/*<li>*/}
                                        {/*<NavLink className="nav-item nav-d6-flex" to="/d6-flex/templates"><span>D6 Flex</span></NavLink>*/}
                                    {/*</li>*/}


                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
            </div>

        )

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);