import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import '../assets/styles/Header.scss';

import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}


class MobileNav extends Component {

    constructor(props){
        super(props);

        this.state = {
            client: {
                portal_first_page_url:'/',
                portal_first_report_url:'/',
                projects_url: '/',
                is_seo_client: "0"
            },

            info_fetched: false,

            current_user:{
                photo: '',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : ''
        }

        this.forceRerender = this.forceRerender.bind(this);

    }

    componentDidMount(){


        this.setState({
            current_user: this.props.current_user,
            client: this.props.client
        });

        //console.log(this.props.client)


        if (this.props.current_user.first_name){
            this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
        }

        window.$j(document).on('click','.backdrop, .mobile-nav a', function () {
            window.$j('.mobile-nav').removeClass('open');
        });


        window.$j(document).on('click','.breadcrumbs', function (e) {
            window.$j('.mobile-nav').addClass('open');
            e.preventDefault();
            return false;
        });


    }


    componentWillReceiveProps(nextProps) {


        //console.log(nextProps.client);

        this.setState({client: nextProps.client});

        //this.setState({current_user: nextProps.current_user});
        //this.setState({ data: nextProps.data });

    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }

    render() {



        if (this.state.current_user.wp_user_id){


            if (this.state.current_user.role === 'client'){


                return (

                    <div className="mobile-nav">

                        <div className="backdrop"></div>

                        <div className="mobile-nav-wrap">

                            <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                            <div className="logged-user">
                                <div>Hello, <Link to={'/' + this.state.current_user.slug + '/profile'}><span className="name">{this.state.current_user.company}</span></Link></div>
                                <div><Link to={'/logout'}><span>Logout</span></Link></div>

                                <Link to={'/' + this.state.current_user.slug + '/profile'}>
                                    <div className="photo">

                                        {
                                            (this.state.current_user.photo)
                                                ?(<img src={this.state.current_user.photo} alt=""/>)
                                                :(<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                        }

                                    </div>
                                </Link>
                            </div>
                            <nav>

                                <ul className="main-menu">

                                    {
                                        this.state.client.portal_first_page_url !== '' &&

                                        <li>
                                            <NavLink className="nav-item nav-dashboard" to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                        </li>
                                    }


                                    {
                                        this.state.client.is_seo_client === "1" &&

                                        <li>
                                            <NavLink className="nav-item nav-reports" to={'/portal/reports/'+this.state.current_user.slug+'/'}><span>Reports</span></NavLink>
                                            {/*<NavLink className="nav-item nav-reports" to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>*/}
                                        </li>
                                    }


                                    {
                                        this.state.client.projects_url &&
                                        <li>
                                            <NavLink className="nav-item nav-work-requests" to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                        </li>
                                    }


                                    <li><NavLink to={'/' + this.state.current_user.slug + '/profile'} className="signout-link"><span>Profile</span></NavLink></li>
                                    <li><NavLink to={'/' + this.state.current_user.slug + '/billing'} className="signout-link"><span>Billing</span></NavLink></li>


                                </ul>

                                <Link to={'/portal/projects/' + this.state.current_user.slug + '/make-request'} className="btn btn-primary btn-sm"><span>Make a Request</span></Link>

                            </nav>
                        </div>
                    </div>

                )


            }else {


                return (

                    <div className="mobile-nav">

                        <div className="backdrop"></div>

                        <div className="mobile-nav-wrap">

                            <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                            <div className="logged-user">
                                <div>Hello, <a href="/wp-admin/"><span className="name">{this.state.current_user.first_name}</span></a></div>
                                <div><Link to={'/logout'}><span>Logout</span></Link></div>

                                <a href="/wp-admin/">
                                    <div className="photo">

                                        {
                                            (this.state.current_user.photo)
                                                ? (<img src={this.state.current_user.photo} alt=""/>)
                                                : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                        }

                                    </div>
                                </a>
                            </div>
                            <nav>
                                <ul className="main-menu">
                                    


                                    {
                                        typeof this.state.client !== 'undefined' && this.state.client.portal_first_page_url !== '' &&

                                        <li>
                                            <NavLink className="nav-item nav-dashboard" to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                        </li>
                                    }


                                    {
                                        typeof this.state.client !== 'undefined' && this.state.client.is_seo_client === "1" &&

                                        <li>
                                            <NavLink className="nav-item nav-reports"  to={'/portal/reports/'+this.state.client.slug+'/'}><span>Reports</span></NavLink>
                                            {/*<NavLink className="nav-item nav-reports"  to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>*/}
                                        </li>
                                    }

                                    {
                                        typeof this.state.client !== 'undefined' && this.state.client.projects_url &&
                                        <li>
                                            <NavLink className="nav-item nav-work-requests"  to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                        </li>
                                    }


                                     {
                                        typeof this.state.client !== 'undefined' && this.state.client.projects_url && this.state.client.slug &&
                                        <li>
                                            <NavLink className="nav-item nav-make-request" to={'/portal/projects/' + this.state.client.slug + '/make-request/'}><span>Make a Request</span></NavLink>
                                        </li>
                                    }


                                    <li>
                                        <NavLink className="nav-item nav-admin" to={'/admin'}><span>Admin</span></NavLink>
                                    </li>


                                </ul>
                            </nav>
                        </div>
                    </div>

                )

            }



        }


        else{



            return (

                <div className="mobile-nav">

                    <div className="backdrop"></div>

                    <div className="mobile-nav-wrap">

                        <a href="#nav-close" className="mobile-nav-close">&nbsp;</a>

                        <div className="logged-user">
                            <div>Hello, <Link to={'/login'}>


                                {
                                    (this.state.client && this.state.client.company) &&

                                    <span className="name">{this.state.client.company}</span>
                                }

                            </Link></div>


                            <div><Link to={'/login'}><span>Sign-In</span></Link></div>

                            <a href="/wp-admin/">
                                <div className="photo">

                                    {
                                        (this.state.client && this.state.client.photo)
                                            ? (<img src={this.state.client.photo} alt=""/>)
                                            : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                    }

                                </div>
                            </a>
                        </div>

                        <nav>
                            <ul className="main-menu">

                                {
                                    this.state.client.portal_first_page_url !== '' &&

                                    <li>
                                        <NavLink className="nav-item nav-dashboard"  to={this.state.client.portal_first_page_url}><span>Projects</span></NavLink>
                                    </li>
                                }


                                {
                                    this.state.client.is_seo_client === "1" &&

                                    <li>
                                        <NavLink className="nav-item nav-reports" to={'/portal/reports/'+this.state.client.slug+'/'}><span>Reports</span></NavLink>
                                        {/*<NavLink className="nav-item nav-reports" to={this.state.client.portal_first_report_url}><span>Reports</span></NavLink>*/}
                                    </li>
                                }

                                {
                                    this.state.client.projects_url &&
                                    <li>
                                        <NavLink className="nav-item nav-work-requests" to={this.state.client.projects_url}><span>Work Requests</span></NavLink>
                                    </li>
                                }



                            </ul>


                            <Link to={'/login/'} className="btn btn-primary btn-sm"><span>Make a Request</span></Link>

                        </nav>
                    </div>
                </div>

            )

        }


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);