import React, { Component } from "react";
import axios from 'axios';
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { setProjectBookSlug, setProjectBookPagePages, setProjectBookInfo } from "../../redux-js/actions/index";

import { authHeader } from '../helpers/helpers'

import Design from './design';
import Discovery from './discovery';
import Development from './development';
import Portal from './portal';
import SEOReports from './seo-reports';

//import QuestionBox from './partials/question-box';

import '../../assets/styles/ProjectBook.scss';


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setProjectBookSlug: project_book_slug => dispatch(setProjectBookSlug(project_book_slug)),
        setProjectBookPagePages: project_book_page_pages => dispatch(setProjectBookPagePages(project_book_page_pages)),
        setProjectBookInfo: project_book_info => dispatch(setProjectBookInfo(project_book_info)),
        //setProjectBookSlug
    };
}


class ProjectBook extends Component {

    constructor(props){
        super(props);

        this.state = {
            project_book_info: {},
            project_book_slug: '',
            project_book_fetched: false,
            project_book_page_pages:[]
        }
    }

    componentDidMount(){


        //console.log(this.props.match.params.project_book_slug);

        this.props.setProjectBookSlug(this.props.match.params.project_book_slug);
        this.setState({project_book_slug: this.props.match.params.project_book_slug});

        this.getProjectBook(this.props);

        window.$j('body').addClass('project-book-page');





        window.onbeforeunload = function (e){

            //window.not_saved = true;

            if(window.not_saved){

                return 'Changes you made may not be saved.';
            }

            // e = e || window.event;
            // var y = e.pageY || e.clientY;
            // if (y < 0){
            //     return "Do You really Want to Close the window ?"
            // }
            // else {
            //     return "Refreshing this page can result in data loss.";
            // }

        }



    }

    componentWillUnmount() {

        window.$j('body').removeClass('project-book-page');
    }


    getProjectBook(props){


        //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal/' + props.match.params.project_book_slug + '/?ref='+window.location.href, authHeader() )
            .then(res => {


                if(res.data.project_book){


                    if(props.match.isExact){
                        props.history.push('/portal/pages/' + props.match.params.project_book_slug + '/portal/');
                    }


                    props.setProjectBookPagePages(res.data.pages);

                    props.setProjectBookInfo({
                            clint_slug: res.data.client_slug,
                            title: res.data.project_book,
                            logo: res.data.project_book_logo,
                            sub_pages: res.data.sub_pages,
                            other_portals: res.data.other_portals,
                            project_book_approved_sitemap_content: res.data.project_book_approved_sitemap_content
                    });



                    this.setState({
                        project_book_fetched: true,
                        project_book_page_pages: res.data.pages,
                    });

                }else{

                    props.history.push('/404');
                }


            })

            .catch((error) => {

                //this.setState({profile_fetched: true});
                //console.log(error.response.data);
                //props.history.push('/login');

                props.history.push('/404');

            });


    }

    componentWillReceiveProps(nextProps) {


        /**
         * We need to redirect/reload the page so that
         **/

        if(this.state.project_book_slug !== nextProps.match.params.project_book_slug){


            //console.log(nextProps.location.pathname);
            //window.location.href = nextProps.location.pathname


            this.props.setProjectBookSlug(nextProps.match.params.project_book_slug);
            this.setState({project_book_slug: nextProps.match.params.project_book_slug});

            this.getProjectBook(nextProps);

            //console.log(nextProps);
            //window.location.href = nextProps.location
        }
    }

    render() {


        if( ! this.state.project_book_fetched ){

            return (<div className="text-center project-book-loader">
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                    </div>);
        }


        return (

                <div>
                    {/*<Route exact={true} path={this.props.match.path} render={(props) => (<Discovery project_book_page_pages={this.state.project_book_page_pages}  {...props}/>)} />*/}


                    <Route path={`${this.props.match.path}/portal`} render={(props) => (<Portal project_book_page_pages={this.state.project_book_page_pages} {...props}/>)} />
                    <Route path={`${this.props.match.path}/seo-reports`} render={(props) => (<SEOReports project_book_page_pages={this.state.project_book_page_pages} {...props}/>)} />
                    <Route path={`${this.props.match.path}/discovery`} render={(props) => (<Discovery project_book_page_pages={this.state.project_book_page_pages} {...props}/>)} />
                    <Route path={`${this.props.match.path}/design`} render={(props) => (<Design project_book_page_pages={this.state.project_book_page_pages} {...props}/>)} />
                    <Route path={`${this.props.match.path}/development`} render={(props) => (<Development project_book_page_pages={this.state.project_book_page_pages} {...props}/>)} />

                    {/*<QuestionBox />*/}
                </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectBook));