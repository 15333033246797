import React, { Component } from "react";
import { Route, withRouter, NavLink } from "react-router-dom";
import axios from 'axios';

import { connect } from "react-redux";

// import Header from '../header'
import Header from '../header-v2';

// import RequestSupportMonthly from './request-support-monthly'
//import MakeRequest from './make-request'
import Profile from './profile'
import Billing from './billing'
import ServicePlan from './service-plan'
//import Requests from './requests'
import { setHeaderInfo } from "../../redux-js/actions/index";

const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class Client extends Component {

    constructor(props){
        super(props);

        this.state = {
            client: {first_name: ''},
            can_access: false,
        }

    }

    componentDidMount(){



        this.props.setHeaderInfo({client_slug: this.props.match.params.client, page: 'profile'});

        /*
         * Check if user can access this page
         */
        if ( ! this.props.current_user.wp_user_id ){

            let location_path = this.props.location.pathname.split('/requests/');

            //allow to view single work request
            if (location_path[1] === undefined || location_path[1] === ''){

                localStorage.setItem('redirect',this.props.location.pathname);



                return this.props.history.push('/login');

            }


        }




        this.setState({can_access:true});


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/profile/', headers)
            .then(res => {

                if (res.data.client){

                    this.setState({client: res.data.client});

                }else {

                    this.props.history.push('/');

                }
            })

            .catch((error) => {

                //console.log(error.response.data);
                this.props.history.push('/');

            });





    }





  render() {


    //if (!this.state.can_access){

        //return null;
    //}


    return (<div>


        {/*<Header client={this.state.client} />*/}
        <Header post_type="profile" />

        { this.state.can_access &&

            <div className="account-page-wrapper">


                <main>
                    <div className="container-fluid">
                        <div className="wrap">


                            <ul className="sub-nav">
                                <li><NavLink exact={true}
                                             to={'/' + this.props.current_user.slug + '/profile'}><img src={process.env.REACT_APP_URL+"/images/profile.png"} alt=""/><span>Profile</span></NavLink>
                                </li>
                                <li><NavLink exact={true}
                                             to={'/' + this.props.current_user.slug + '/billing'}><img src={process.env.REACT_APP_URL+"/images/billing.png"} alt=""/><span>My Billing</span></NavLink>
                                </li>
                                <li><NavLink exact={true} to={'/' + this.props.current_user.slug + '/service-plan'}><img src={process.env.REACT_APP_URL+"/images/services.png"} alt=""/><span>Your Service Plan</span></NavLink>
                                </li>
                            </ul>



                            <div className="page-content">

                                <Route
                                    exact
                                    path={this.props.match.path}
                                    render={() =>

                                        <div>

                                            {/*<h2>Welcome Back {this.props.current_user.first_name}!</h2>*/}

                                            {/*<div>*/}
                                            {/*<Link className="btn btn-primary" to={'/' + this.props.current_user.slug + '/request-support'}><span>Request Work</span></Link>*/}
                                            {/*</div>*/}

                                        </div>

                                    }
                                />

                                {/*<Route path={`${this.props.match.path}/request-support`} component={RequestSupportMonthly}/>*/}
                                {/*<Route path={`${this.props.match.path}/make-request`} component={MakeRequest}/>*/}
                                <Route path={`${this.props.match.path}/profile`} component={Profile}/>
                                <Route path={`${this.props.match.path}/billing`} component={Billing}/>
                                <Route path={`${this.props.match.path}/service-plan`} component={ServicePlan}/>
                                {/*<Route path={`${this.props.match.path}/requests`} component={Requests}/>*/}

                            </div>


                        </div>
                    </div>
                </main>

            </div>



        }

    </div>);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Client));
