import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setHeaderInfo } from "../../../redux-js/actions/index";
import moment from 'moment';
import { convertServerDateToLocal } from '../../helpers/helpers'

import  Comments from  './../partials/comments-v2';

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class SingleRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errorMsg: '',
            msg: '',
            btnEnabled: true,

            request: {

            },

            client:{},
            comment:{},

            request_fetched: false,
            current_user: {},

        };

        this.handleInputChange  = this.handleInputChange.bind(this);
        this.setStatus          = this.setStatus.bind(this);
    }


    postRequestComment(){

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/request-comments/', this.state.comment )
            .then(res => {


            })

            .catch((error) => {


            });

    }

    getRequest(){

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/get-request/' + this.props.request_long_id + '/' )
            .then(res => {


                let request = res.data.request;


                if(request) {



                    if(this.props.public && request.client.support_url_slug){

                        /**
                         * Redirect to client portal
                         */

                        window.location.href =  '/portal/projects/'+ request.client.support_url_slug + '/request/' + this.props.request_long_id + '/';


                    }else{

                        this.setState({
                            request_fetched: true,
                            request: request,
                        });

                    }


                }

                else{

                    window.location.href = '/';
                    //this.props.history.push('/' );

                }

            })

            .catch((error) => {

                this.setState({
                    request_fetched: true,
                })
            });




    }

    componentDidMount(){


        if (this.props.current_user.role === 'client'){


            this.props.setHeaderInfo({client_slug: this.props.current_user.slug, page: 'project-scope'});

        }


        this.getRequest();



    }


    componentWillUnmount(){


    }


    handleSubmitComment(){



    }


    handleInputChange(event) {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        if (target.type === 'checkbox'){
            value = target.checked;
        }

        this.setState({[name]: value});


        //this.setState({[name]: value});


    }

    setStatus(status){


        window.$j('.request-status').removeClass('status-pending status-in-progress status-completed');

        window.$j('.request-status').text(status).addClass(this.getStatusClass(status));

        let data = {
            status: status,
            request_long_id: this.state.request.long_id
        };

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/request-status-set/', data, headers )
            .then(res => {


            })

            .catch((error) => {


            });

    }


    getDate(date){


        var d = convertServerDateToLocal(date);

        return moment(d).fromNow(true) + ' ago';

    }

    getStatusClass(status){

        return 'status-' + status.toLowerCase(status);

    }



    render() {



        return (


            <div>

                {
                    this.state.request_fetched &&

                    (
                        <div className="container-fluid">
                            <div className="wrap">
                                <div className="page-content">

                                    <div className="single-request-wrap single-request-wrap-v2">

                                        <div className="row">
                                            <div className="col-lg-12">

                                                <h3>{this.state.request.subject}</h3>

                                                <div className="request-meta">


                                                    {
                                                        !this.state.request.client.id &&

                                                        <span>
                                                                    {this.state.request.first_name + ' ' + this.state.request.last_name}
                                                                </span>
                                                    }


                                                    {
                                                        this.state.request.client.id  &&

                                                        <span>
                                                                    {this.state.request.client.first_name + ' ' + this.state.request.client.last_name +' / '+ this.state.request.client.company}
                                                                </span>
                                                    }


                                                    <span className="sep">|</span> <span className="date" title={this.state.request.date}>{this.getDate(this.state.request.date_converted)}</span>

                                                    {
                                                        this.props.current_user.role === 'administrator' &&
                                                        <div className="request-status-wrap">
                                                            <span className={'request-status change-status '+ this.getStatusClass(this.state.request.status)}>{this.state.request.status}</span>

                                                            <div className="change-status-wrap">
                                                                <button onClick={()=>this.setStatus('Pending')} className="pending">Pending</button>
                                                                <button onClick={()=>this.setStatus('In-Progress')} className="in-progress">In-Progress</button>
                                                                <button onClick={()=>this.setStatus('Completed')} className="completed">Completed</button>
                                                            </div>

                                                        </div>

                                                    }

                                                    {
                                                        this.props.current_user.role !== 'administrator' &&
                                                        <div className="request-status-wrap">
                                                            <span className={'request-status '+ this.getStatusClass(this.state.request.status)}>{this.state.request.status}</span>
                                                        </div>
                                                    }



                                                </div>


                                                <div className="request-content-wrap">
                                                    <div className="request-content">
                                                        <div dangerouslySetInnerHTML={{__html: this.state.request.request}}></div>

                                                        {
                                                            (this.state.request.attachments.length)

                                                                ? (
                                                                <div className="request-attachments">

                                                                    <strong><span className="fa fa-paperclip"></span> Attachments</strong>

                                                                    {this.state.request.attachments.map((attachment, i) =>
                                                                        <div className="attachment-item item-row" key={i}>
                                                                            <a rel="noopener noreferrer" target="_blank"
                                                                               href={attachment.url}>{attachment.file_name}</a>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                                : ('')
                                                        }


                                                    </div>


                                                    <Comments request={this.state.request} request_long_id={this.props.request_long_id} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    )
                }

            </div>



        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleRequest);