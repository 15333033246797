import React, { Component } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import carmelo from '../../assets/images/carmelo.jpg';

import '../../assets/styles/ServicePlan.scss';



const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}



class ServicePlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            service_plan_text: '',
            payment_policy_text: '',
            service_plan: [],
            service_plan_fetched: false,
            carmelo_msg: '',
            main_services: '',
            hours_allocation: '',
            questionnaire_info: '',
            policies: '',
            plan: {},
            max_hours_reached : 0,
            proceed_within_hours : 0,
            payment_policies:[],
        };



        this.handleInputChange  = this.handleInputChange.bind(this);

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;


        this.setState({[name]: value}, function(){
            this.saveChanges()
        }.bind(this));


    }



    componentWillUnmount(){

        window.$j('.sub-nav').css({display:'none'});
        window.$j('body').removeClass('client-account-page');
    }

    saveChanges(){



        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };


        let data = {
            max_hours_reached    : this.state.max_hours_reached,
            proceed_within_hours : this.state.proceed_within_hours,
            plan_id              : this.state.plan.plan_id
        };


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/service-plan/save', data, headers)
            .then(res => {


                if (!res.data.success){

                    //window.location.href = '/login';
                }

            })

            .catch((error) => {

                window.location.href = '/login';
                //this.setState({service_plan_fetched: true});
                //console.log(error.response.data);

            });



    }

    componentDidMount(){


        this.setState({service_plan_fetched: false});


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/service-plan', headers)
            .then(res => {


                if (false && res.data.service_plan){

                    this.setState({

                        service_plan            : res.data.service_plan,
                        main_services           : res.data.main_services,
                        carmelo_msg             : res.data.carmelo_msg,
                        hours_allocation        : res.data.hours_allocation,
                        questionnaire_info      : res.data.questionnaire_info,
                        policies                : res.data.policies,
                        plan                    : res.data.plan,
                        max_hours_reached       : res.data.plan.max_hours_reached,
                        proceed_within_hours    : res.data.plan.proceed_within_hours,

                    })
                }

                this.setState({
                    service_plan_fetched: true,
                    service_plan_text: res.data.service_plan_text,
                    payment_policy_text: res.data.payment_policy_text,
                    payment_policies: res.data.payment_policies,
                    service_plan: res.data.service_plan
                });

            })

            .catch((error) => {


                //window.location.href = '/login';
                //this.setState({service_plan_fetched: true});
                //console.log(error.response.data);

            });


        window.$j('.sub-nav').css({display:'block'});

        window.$j('body').addClass('client-account-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});
    }


    render() {

        if (!this.state.service_plan_fetched){

            return (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>);
        }


        return (
            <div>


                <div className="service-plan-content">




                    {
                        this.state.service_plan_text !=='' &&

                        <div className="service-plan-content-text">
                            <div dangerouslySetInnerHTML={{__html: this.state.service_plan_text}}></div>


                            {
                                this.state.service_plan.length !== 0 &&

                                <div className="accordion" id="accordionServicePlans">

                                    {this.state.service_plan.map((item, i) =>

                                        <div className="card" key={i}>
                                            <div className="card-header" id={'headingx' + i}>
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target={'#collapsex' + i}
                                                            aria-expanded="true" aria-controls={'collapsex' + i}>
                                                        <span dangerouslySetInnerHTML={{__html: item.title}}></span>
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id={'collapsex' + i} className="collapse card-content"
                                                 aria-labelledby={'headingx' + i}
                                                 data-parent="#accordionServicePlans">
                                                <div className="card-body">
                                                    <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                </div>

                            }


                        </div>


                    }

                    {
                        this.state.payment_policy_text !=='' &&

                        <div className="service-plan-content-text">
                            <div dangerouslySetInnerHTML={{__html: this.state.payment_policy_text}}></div>


                            {
                                this.state.payment_policies.length &&

                                <div className="accordion" id="accordionPaymentPolicies">

                                    {this.state.payment_policies.map((item, i) =>

                                        <div className="card" key={i}>
                                            <div className="card-header" id={'heading' + i}>
                                                <h2 className="mb-0">
                                                    <button className="btn btn-link collapsed" type="button"
                                                            data-toggle="collapse" data-target={'#collapse' + i}
                                                            aria-expanded="true" aria-controls={'collapse' + i}>
                                                        <span dangerouslySetInnerHTML={{__html: item.title}}></span>
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id={'collapse' + i} className="collapse card-content"
                                                 aria-labelledby={'heading' + i}
                                                 data-parent="#accordionPaymentPolicies">
                                                <div className="card-body">
                                                    <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                </div>

                            }


                        </div>
                    }

                    {
                        false && this.state.service_plan !== ''  &&

                        <div className="carmelo-main-services-wrap">
                            <div className="row">

                                <div className="col-lg-6">

                                    <div className="tip-box with-carmelo carmelo-left">
                                        <h5>Hey {this.props.current_user.first_name}!</h5>

                                        <div dangerouslySetInnerHTML={{__html: this.state.carmelo_msg}}></div>

                                        <div className="carmelo">
                                            <img src={carmelo} alt=""/>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-lg-6">

                                    <div className="main-services">
                                        <h3>Main Services</h3>

                                        <div dangerouslySetInnerHTML={{__html: this.state.main_services}}></div>

                                        <div className="hours-allocation">
                                            <div dangerouslySetInnerHTML={{__html: this.state.hours_allocation}}></div>
                                        </div>


                                        <div className="max-hours-reached">
                                            <div className="max-hours-q1"><strong>If we reach the max hours, should
                                                D6:</strong></div>
                                            <div className="option">
                                                <label><span
                                                    className={parseInt(this.state.max_hours_reached) === 1 ? 'active' : ''}><input
                                                    type="radio" name="max_hours_reached" value="1"
                                                    checked={parseInt(this.state.max_hours_reached) === 1}
                                                    onChange={this.handleInputChange}/></span> Pause Work & Notify You
                                                    with Estimate</label>
                                            </div>
                                            <div className="option">
                                                <label><span
                                                    className={parseInt(this.state.max_hours_reached) === 2 ? 'active' : ''}><input
                                                    type="radio" name="max_hours_reached" value="2"
                                                    checked={parseInt(this.state.max_hours_reached) === 2}
                                                    onChange={this.handleInputChange}/></span> Proceed Working if within
                                                    <input type="text" className="proceed_within_hours"
                                                           onChange={this.handleInputChange}
                                                           value={this.state.proceed_within_hours}
                                                           name="proceed_within_hours"/> hours</label>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    }





                    {
                        false && (this.state.questionnaire_info || this.state.policies)
                            ?
                            <div className="questionnaire-info-policies">
                                <div className="row">

                                    {this.state.questionnaire_info &&

                                    <div className="col-lg-6">

                                        <h3>Your Questionnaire Info</h3>
                                        <div dangerouslySetInnerHTML={{__html: this.state.questionnaire_info}}></div>

                                    </div>
                                    }

                                    {this.state.policies &&
                                    <div className="col-lg-6">

                                        <h3>Policies</h3>
                                        <div dangerouslySetInnerHTML={{__html: this.state.policies}}></div>

                                    </div>
                                    }

                                </div>
                            </div>
                            :
                            null
                    }


                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicePlan));