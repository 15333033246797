import React, { Component } from "react";


import { connect } from "react-redux";
import { Route } from "react-router-dom";

import RegularMakeRequestForm from './partials/make-request-form'
import InteractiveMakeRequestForm from './partials/make-request-interactive-form'
import { setHeaderInfo } from "../../redux-js/actions/index";
import Header from '../header-public'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class MakeRequest extends Component {


    constructor(props){
        super(props);

        this.state = {
        }

    }

    componentDidMount(){

        window.$j('body').addClass('make-request-page');



        if (this.props.current_user.role === 'client'){

            //console.log(this.props.current_user);

            //this.props.setHeaderInfo({client_slug: this.props.current_user.slug, page: 'project-scope'});

            ///

            this.props.history.push("/portal/projects/"+this.props.current_user.slug+"/make-request/");

        }


        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});


    }

    componentWillUnmount(){

        window.$j('body').removeClass('make-request-page');
    }

    render() {


        return (

        <div className="page-wrap project-book-page-wrap">

            <div>

                <Header />

                <main>

                    <div className="container-fluid">
                        <div className="wrap">
                            <div className="page-content">

                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className="make-request-form-wrap">
                                            <h2>Make a Request</h2>
                                            <p>Use this page to make a request to D6. We will review & get back to you with a scope/turnaround. </p>



                                            <Route exact path={`${this.props.match.path}/`} component={RegularMakeRequestForm}/>
                                            <Route exact path={`${this.props.match.path}/interactive-form`} component={InteractiveMakeRequestForm}/>

                                            {/*<RequestForm client_slug={this.props.match.params.client} />*/}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </main>
            </div>

        </div>



        );
    }
}
 

export default connect(mapStateToProps, mapDispatchToProps)(MakeRequest);