import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { setHeaderInfo } from "../../redux-js/actions/index";



const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class BlogPostTracker extends Component {

    constructor(props){
        super(props);

        this.state = {

        }
    }

    iframeEvents(){


        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        // Listen to message from child window
        let currentUrl = '';

        eventer(messageEvent,function(e) {
            if(e.data.event_id === 'blog-post-tracker'){
                
                if(currentUrl !== e.data.data.url){

                    //window.history.pushState([{urlPath:e.data.data.url}],"",e.data.data.url)
                    window.history.replaceState([{urlPath:e.data.data.url}],"",e.data.data.url)
                }

                currentUrl = e.data.data.url;
            }

        },false);
    }

    componentDidMount(){

        //this.props.current_user.role


        this.props.setHeaderInfo({client_slug: this.props.match.params.client_slug, page: 'profile'});

        //console.log(this.props.current_user);

        /***
         * Important
         */


         window.$j('body, html').addClass('blog-post-tracker');

        //window.$j('main').css({'height': (window.$j(window).height() -70) + '!important' });
        //window.$j('main .d6-flex-content').css({'min-height':window.$j(window).height() -69});

        //console.log(this.props.match.url);
        //console.log(this.props.match.params);


        if(this.props.match.params.client_slug){

            localStorage.setItem('blogPostTrackerClient', this.props.match.params.client_slug);

            //this.props.match.params.client
        }else{

            // const blogPostTrackerClient = localStorage.getItem('blogPostTrackerClient');

            // if(blogPostTrackerClient){
            //     window.location.href = '/blog-post-tracker/'+ blogPostTrackerClient
            // }else{
            //     window.location.href = '/'
            // }
        }

        

        this.iframeEvents();

    }

    componentWillUnmount(){
        window.$j('body, html').removeClass('blog-post-tracker');
    }


    componentWillReceiveProps(nextProps) {


    }


    render() {
        

        return (

                <iframe id="blog-post-tracker-iframe" allow="clipboard-write" src={`${process.env.REACT_APP_BLOG_POST_TRACKER}/login?id=${this.props.current_user.wp_user_id}&name=${this.props.current_user.first_name}&email=${this.props.current_user.email}&role=${this.props.current_user.role}&client=${this.props.match.params.client_slug?this.props.match.params.client_slug:''}&url=${this.props.match.url}`} title={"Blog Post Tracker"} />

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlogPostTracker));