import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
//import { authHeader } from '../helpers/helpers'
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { authHeader } from '../helpers/helpers'
import ReportsPageDescription from './partials/report-page-description';

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ReportsConsolidatedOganicKeywordsTracker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_user: {},
            client_slug: '',
            reports: {
                dates: []
            },

            reports_fetched: false,

            keyword_rankings_start_date: '',
            keyword_rankings_end_date: '',
            is_uploading_keyword_rankings_data: false,
            showStartDateDropdown: false,
            showEndDateDropdown: false,

            filter_data: { keywords: '', hide_lost: false, position: '', ranking_from: '', ranking_to: '' , position_from: '', position_to: ''},

            save_error: '',
            save_msg: '',
            reload: '',

            current_report_year_month: '',

        };


        this.handleDropFiles                = this.handleDropFiles.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({filter_data: {...this.state.filter_data, [name]: value}});

    }


    handleSubmit(event) {

        event.preventDefault();

        this.setState({save_msg: '', save_error: ''})


        let formData = new FormData();

        formData.append('filter_data',JSON.stringify(this.state.filter_data));


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token, 'Content-Type': 'multipart/form-data'} };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/save-consolidated-organic-keywords-filter-tracker?slug='+this.state.client_slug, formData , headers)
            .then(res => {

             
                if (res.data.success){

                    this.setState({save_msg: res.data.msg, reload: Math.random()})

                }else {

                    this.setState({save_error: res.data.msg})

                }

                this.setState({'btnEnabled': true});
            })

            .catch((error) => {

                this.setState({save_error: error.response.data.message})

            });


    }



    handleDropFiles (acceptedFiles){


        let formData = new FormData();

        for (var i = 0; i < acceptedFiles.length; i++){

            formData.append('Files[]',acceptedFiles[i]);
        }

        //formData.append('key', this.state.current_page.key);
        //formData.append('project_book', this.props.match.params.project_book_slug);


        this.setState({is_uploading_keyword_rankings_data: true});


        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/upload-consolidated-organic-keywords-data?slug='+this.state.client_slug, formData, authHeader())
            .then(res => {


                this.setState({
                    is_uploading_keyword_rankings_data: false, reload: Math.random()
                });


                if (res.data.errors){

                    alert(res.data.errors);

                } else{

                    this.getReportDetails(this.props, this.state.current_report_year_month);

                }

            }).catch((error) => {

            this.setState({is_uploading_keyword_rankings_data: false});
        });



    }


    getReportDetails(props, report_year_month){


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/consolidated-organic-keywords-tracker/?report_year_month='+report_year_month, authHeader() )
            .then(res => {


                let reports = res.data.reports;

                this.setState({
                    reports_fetched: true,
                    reports: reports ,
                    keyword_rankings_start_date : reports.start_date,
                    keyword_rankings_end_date   : moment(`${report_year_month}-01`, 'YYYY-MM-DD').format('MM/DD/YYYY')//reports.end_date,
                });


                if(reports.filter_data){

                    this.setState({ filter_data: JSON.parse(reports.filter_data) });
                }

            })


            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){


        let end_date = moment(this.props.current_report_year_month+'-01', 'YYYY-MM-DD');
        end_date.endOf('month');
        end_date.toDate();
        end_date = end_date.format('MM/DD/YYYY');


        this.setState({
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug,
            keyword_rankings_end_date   : end_date,
            current_report_year_month   : this.props.current_report_year_month
        },function () {

            this.getReportDetails(this.props, this.props.current_report_year_month);

        });


    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }



    render() {


        let start_date_text = '';
        let end_date_text = '';

        start_date_text = 'Compare with: ' + moment(this.state.keyword_rankings_start_date, "MM/DD/YYYY").format("MMMM YYYY")
        end_date_text   = moment(this.state.keyword_rankings_end_date, "MM/DD/YYYY").format("MMMM YYYY")


        if((this.state.keyword_rankings_start_date) && (this.state.keyword_rankings_end_date)){

            var isafter = moment(this.state.keyword_rankings_end_date, "MM/DD/YYYY").isAfter(moment(this.state.keyword_rankings_start_date, "MM/DD/YYYY"));

            if( !isafter ){

                /**
                 * The start date is later date than end date
                 * Example: Start: June 2020 - End: April 2020
                 */

                if(this.state.keyword_rankings_start_date === this.state.keyword_rankings_end_date){


                }
                
                else{

                    //swap dates
                    this.setState({keyword_rankings_start_date : this.state.keyword_rankings_end_date, keyword_rankings_end_date: this.state.keyword_rankings_start_date});
                }

            }

        }

        return (


            <div className="report-block">

                {
                    !this.state.reports_fetched &&
                    <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                }


                {
                    this.state.reports_fetched && this.state.reports && this.state.reports.dates.length > 0&&

                    <div className="report-block-content">

                        <div className="report-block-header with-highlights" style={{display:'block',position:'relative'}}>
                            <div className="">
                                <div className=""><h2>Progress Tracker</h2></div>
                                {/* <div className="description">{ moment(this.state.keyword_rankings_start_date, "MM/DD/YYYY").format("MMMM  YYYY") + ' - ' + moment(this.state.keyword_rankings_end_date, "MM/DD/YYYY").format("MMMM  YYYY")}</div> */}
                                <div className="description">{ moment(this.state.current_report_year_month+'-01', "YYYY-MM-DD").format("MMMM  YYYY") }</div>
                            </div>

                            <div className="page-description--highlights">

                                

                                <ReportsPageDescription client_slug={this.props.client_slug} page="consolidated-organic-keyword-rankings-tracker" current_user={this.state.current_user}/>
                                

                                <div className="page-highlights-wrap">
                                    <div className="page-highlights">

                                    {
                                            this.state.reports.highlights.highlight1.cnt > 0 &&
                                            <div className="page-highlight-item green">
                                                <div className="page-highlight-item-inner">
                                                    <div className="icon" dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight1.icon}}></div>
                                                    <h4>{parseInt(this.state.reports.highlights.highlight1.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight1.text}}></span>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.reports.highlights.highlight2.cnt > 0 &&
                                            <div className="page-highlight-item purple">
                                                <div className="page-highlight-item-inner">
                                                    <div className="icon" dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight2.icon}}></div>
                                                    <h4>{parseInt(this.state.reports.highlights.highlight2.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight2.text}}></span>
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            this.state.reports.highlights.highlight3.cnt > 0 &&
                                            
                                            <div className="page-highlight-item blue">
                                                <div className="page-highlight-item-inner">
                                                    <div className="icon" dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight3.icon}}></div>
                                                    <h4>{parseInt(this.state.reports.highlights.highlight3.cnt).toLocaleString()}</h4><span dangerouslySetInnerHTML={{__html: this.state.reports.highlights.highlight3.text}}></span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="keyword-rankings-filter">

                                <span>Filter: </span>

                                <div className="organic-keywords-filter-wrap end-date-wrap">

                                    <div className="selected-date" onClick={()=>this.setState({showEndDateDropdown: !this.state.showEndDateDropdown, showStartDateDropdown: false})}><i className="fa fa-calendar-alt"></i> {end_date_text}</div>
                                    
                                    <ul className={(this.state.showEndDateDropdown)?'organic-keywords-filter show': 'organic-keywords-filter'}>
                                        {this.state.reports.dates.map((year_dates,i) =>
                                            <li key={i} className={''}>
                                                <strong>{year_dates.year}</strong>

                                                <div className="dates">
                                                    {year_dates.dates.map((date,i) =>
                                                        <div key={i} className={this.state.keyword_rankings_end_date === date.date?'cur':''} 
                                                            onClick={()=>{
                                                                this.setState({keyword_rankings_end_date: date.date, showEndDateDropdown: !this.state.showEndDateDropdown});

                                                                let report_year_month = date.date.split('/');
                                                                this.setState({current_report_year_month: `${report_year_month[2]}-${report_year_month[0]}`})
                                                                this.getReportDetails(this.props, `${report_year_month[2]}-${report_year_month[0]}`)
                                                            }}
                                                        >
                                                            {date.month}
                                                        </div>
                                                    )}
                                                </div>

                                            </li>
                                        )}
                                    </ul>
                                </div>


                                <div className="organic-keywords-filter-wrap start-date-wrap">

                                    <div className="selected-date" onClick={()=>this.setState({showStartDateDropdown: !this.state.showStartDateDropdown, showEndDateDropdown: false})}>{start_date_text}</div>
                                    
                                    <ul className={(this.state.showStartDateDropdown)?'organic-keywords-filter show': 'organic-keywords-filter'}>
                                        {this.state.reports.dates.map((year_dates,i) =>
                                            <li key={i} className={''}>
                                                <strong>{year_dates.year}</strong>

                                                <div className="dates">
                                                    {year_dates.dates.map((date,i) =>
                                                    
                                                        <div key={i} className={this.state.keyword_rankings_start_date === date.date?'cur':''} onClick={()=>this.setState({keyword_rankings_start_date: date.date, showStartDateDropdown: !this.state.showStartDateDropdown})}>
                                                            {date.month}
                                                        </div>
                                                    )}
                                                </div>

                                            </li>
                                        )}
                                    </ul>
                                </div>

                                


                            </div>
                        </div>


                        
                        <iframe src={ process.env.REACT_APP_URL + '/?action=d6-spreadsheet-consolidated-organic-keywords-tracker&client='+this.state.client_slug+'&date_start='+this.state.keyword_rankings_start_date+'&date_end='+this.state.keyword_rankings_end_date+'&reload='+this.state.reload} title="Keyword Ranking" className="d6-spreadsheet spreadsheet-v3eInQxvlqKU" scrolling="no" frameBorder="0" style={{'height': '877px'}}></iframe>


                        

                    </div>

                }


                { this.state.reports_fetched && this.state.current_user && this.state.current_user.role === 'administrator' &&

                    <div className="report-block-footer">

                        {true &&
                        <Dropzone
                            onDrop={this.handleDropFiles}

                            accept="text/csv"
                            multiple={true}
                            //maxSize={200000}
                        >

                            {({getRootProps, getInputProps}) => (

                                <div className="">

                                    <div>
                                        <div className="attach-file-btn" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <button className="btn btn-secondary"> <i className="fa fa-upload"></i> Upload Data</button>
                                        </div>
                                    </div>

                                    {
                                        this.state.is_uploading_keyword_rankings_data && <div><i className="fa fa-spinner fa-spin"></i></div>
                                    }

                                </div>

                            )}
                        </Dropzone>
                        }

                        <div className="d6-filter-keywords">
                            <form action="" onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>
                                <div className="row">

                                    <div className="col-md-12">
                                        <label className="form-lbl">Show Keywords Contains: </label>
                                        <textarea name="keywords" id="" cols="30" rows="3" className="form-control" placeholder="Enter comma seperated keywords" onChange={this.handleInputChange} value={this.state.filter_data.keywords}></textarea>
                                    </div>


                                    <div className="col-md-12">
                                        <label className="form-lbl">Show Keywords - Current Month Position:</label>

                                        <div className="filter-from-to">
                                            <div className="row">

                                                <div className="col-md-6">
                                                    From:
                                                    <input min={1} type="number" className="form-control" placeholder="1" name="position_from" value={this.state.filter_data.position_from} onChange={this.handleInputChange}/>
                                                </div>

                                                <div className="col-md-6">
                                                    To:
                                                    <input max={100} type="number" className="form-control" placeholder="100" name="position_to" value={this.state.filter_data.position_to} onChange={this.handleInputChange}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <label className="form-lbl">Show Keywords - Ranking Increase/Decrease:</label>

                                        <div className="filter-from-to">
                                            <div className="row">

                                                <div className="col-md-6">
                                                    From:
                                                    <input min={-100} type="number" className="form-control" placeholder="-100" name="ranking_from" value={this.state.filter_data.ranking_from} onChange={this.handleInputChange}/>
                                                </div>

                                                <div className="col-md-6">
                                                    To:
                                                    <input max={100} type="number" className="form-control" placeholder="100" name="ranking_to" value={this.state.filter_data.ranking_to} onChange={this.handleInputChange}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <label className="form-lbl">Hide Lost Keywords:</label>
                                        <div>
                                            <label className="checkbox-ui-wrap">
                                                <input type="checkbox" name="hide_lost" checked={this.state.filter_data.hide_lost} onChange={this.handleInputChange}/>
                                                <span className="checkbox-ui"></span>
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="form-submit">
                                    <button className="btn btn-primary" type="submit"><span> Save </span></button>

                                    {
                                        this.state.save_msg !== '' && 
                                        <div className="save-success-smg">{this.state.save_msg}</div>
                                    }

                                    {
                                        this.state.save_error !== '' && 
                                        <div className="save-error-smg">{this.state.save_error}</div>
                                    }
                                </div>

                            </form>
                        </div>

                    </div>

                }

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsConsolidatedOganicKeywordsTracker);