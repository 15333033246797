import React, { Component } from "react";


import { connect } from "react-redux";
import { Route } from "react-router-dom";

import RegularMakeRequestForm from './partials/make-request-form'
import InteractiveMakeRequestForm from './partials/make-request-interactive-form'
import { setHeaderInfo } from "../../redux-js/actions/index";

import '../../assets/styles/Editor.scss';


const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class MakeRequest extends Component {


    constructor(props){
        super(props);

        this.state = {
        }

    }

    componentDidMount(){

        window.$j('body').addClass('make-request-page');



        if (!this.props.match.params.client_slug){

            if (this.props.current_user.role === 'client'){


                window.location.href = '/portal/projects/' + this.props.current_user.slug + '/';
                //this.props.history.push('/portal/projects/' + this.props.current_user.slug + '/' );

            }else{

                //window.location.href = '/';
                //this.props.history.push('/' );
            }
        }

        else {

            //if (this.props.current_user.role !== 'client'){
                //this.props.history.push('/make-request/' );
            //}


        }


        this.props.setHeaderInfo({client_slug: this.props.match.params.client_slug, page: 'project-scope'});


    }

    componentWillUnmount(){

        window.$j('body').removeClass('make-request-page');
    }

    render() {


        return (
            <div className="container-fluid">
                <div className="wrap">
                    <div className="page-content">

                        <div className="row">
                            <div className="col-lg-12">

                                <div className="make-request-form-wrap">
                                    <h2>Make a Request</h2>
                                    <p>Use this page to make a request to D6. We will review & get back to you with a scope/turnaround. </p>



                                    {/*<div className="request-form-type">*/}
                                        {/*<div className="row">*/}
                                            {/*<div className="col-md-6">*/}
                                                {/*<NavLink exact activeClassName='active' to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/'}><span>Start a Request via Message</span></NavLink>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-6">*/}
                                                {/*<NavLink exact activeClassName='active' to={'/portal/projects/'+this.props.match.params.client_slug+'/make-request/interactive-form'}><span>Make a Request Using our Interactive Form</span></NavLink>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                    {/*</div>*/}




                                    <Route exact path={`${this.props.match.path}/`} component={RegularMakeRequestForm}/>
                                    <Route exact path={`${this.props.match.path}/interactive-form`} component={InteractiveMakeRequestForm}/>

                                    {/*<RequestForm client_slug={this.props.match.params.client} />*/}
                                </div>
                            </div>

                        </div>

                      </div>
                </div>

            </div>
        );
    }
}
 

export default connect(mapStateToProps, mapDispatchToProps)(MakeRequest);