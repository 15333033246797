import React, { Component } from "react";
import {  withRouter } from "react-router-dom";//Link,
import axios from 'axios';
import { connect } from "react-redux";
import { addWorkRequest } from "../../redux-js/actions/index";
import moment from 'moment';
import '../../assets/styles/Request.scss';
import Header from '../header-admin';


const mapStateToProps = state => {
    return { work_requests: state.work_requests, ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        addWorkRequest: work_request => dispatch(addWorkRequest(work_request))
    };
}


class Admin extends Component {

    constructor(props){
        super(props);

        this.state = {

            portals: [],
            projects: [],
            updated_pages: [],
            fetched: false
        }
    }

    componentWillUnmount(){

        this.props.addWorkRequest({});//reset requests in the store so that it will not show in other work request
    }



    componentDidMount(){


        if (this.props.current_user.role !== 'administrator' && this.props.current_user.role !== 'staff'){
            this.props.history.push('/login');

            return;
        }

        this.getWorkRequests();

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }


    getWorkRequests(){

        //if (window.location.pathname.split("/").pop() === '' || window.location.pathname.split("/").pop() === 'requests'){

        /*
         * Run only this block if this is really a /requests page
         * Because it also run even on single work request page
         */


        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/support-admin/', headers)
            .then(res => {

                if (res.data.success) {


                    this.setState({
                        portals:res.data.portals,
                        projects:res.data.projects,
                        updated_pages: res.data.updated_pages
                    });

                } else {

                    //redirect here
                }

                this.setState({fetched: true});
            })


            .catch((error) => {

                this.props.history.push('/login');

                //console.log(error.response.data);
                //this.setState({fetched: true});

            });

    }

    convertServerDateToLocal(date) {

        // EST - UTC offset: 5 hours
        var offset = 5.0,

        /*
         - calculate the difference between the server date and UTC
         - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
         - the time-zone offset is the difference, in minutes, between UTC and local time
         - 60000 milliseconds = 60 seconds = 1 minute
         */
        serverDate = new Date(date),
        utc = serverDate.getTime() - (serverDate.getTimezoneOffset() * 60000),

        /*
         - apply the offset between UTC and EST (5 hours)
         - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
         */
        clientDate = new Date(utc + (3600000 * offset));

        return clientDate.toLocaleString();
    }

    getDate(date){

        //var d = new Date(date + ' UTC');
        var d = this.convertServerDateToLocal(date);

        return moment(d).fromNow();
    }


    render() {

        return (
            <div className="page-wrap">

                <div>

                    <Header />

                    <main>
                        <div className="container-fluid">
                            <div className="wrap">
                                <div className="page-content no-bottom-top-color" style={{maxWidth:'1000px', margin:'0 auto'}}>



                                    {

                                        !this.state.fetched
                                            ?
                                            (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>)
                                            :
                                            ('')
                                    }





                                    {
                                        this.state.fetched &&

                                        (
                                            <div className="row admin-dashboard-boxes">

                                                <div className="col-md-12">
                                                    <h3>PORTALS</h3>

                                                    {
                                                        <div className="admin-list">
                                                            {this.state.portals.map((portal,i) =>

                                                                <div className="list-item item-row" key={i}>
                                                                    <a href={portal.url}>
                                                                        <span className="logo"><img src={portal.photo} alt=""/></span>
                                                                        <span className="title">{portal.title}</span>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>

                                                    }
                                                </div>


                                                <div className="col-md-12">
                                                    <h3>PROJECTS</h3>

                                                    {
                                                        <div className="admin-list">
                                                            {this.state.projects.map((project,i) =>

                                                                <div className="list-item item-row" key={i}>
                                                                    <a href={project.url}>
                                                                        <span className="logo"><img src={project.photo} alt=""/></span>
                                                                        <span className="title">{project.title}</span>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>

                                                    }

                                                </div>


                                                <div className="col-md-12">
                                                    <h3>Recently Updated/Created Pages</h3>

                                                    {
                                                        <div className="admin-list">
                                                            {this.state.updated_pages.map((page,i) =>

                                                                <div className="list-item item-row" key={i}>
                                                                    <a target="_blank" rel="noopener noreferrer" href={page.url}>
                                                                        <span className="logo"><img src={page.photo} alt=""/></span>
                                                                        <span className="title" style={{lineHeight:1.4}}>{page.title}</span>
                                                                        <span className="date" title={page.date}>{this.getDate(page.date)}</span>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>

                                                    }

                                                </div>


                                            </div>
                                        )



                                    }








                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        );


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));