import React, { Component } from "react";
//import Header from '../header-v2';

//import { Link } from "react-router-dom";
import '../../assets/styles/Request.scss';
import { connect } from "react-redux";
import { setHeaderInfo } from "../../redux-js/actions/index";
import Header from '../header-public'
import RequestSingle from './partials/request-single'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class SingleRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {


        };

    }


    componentDidMount(){


        window.$j('body').addClass('make-request-page single-request-page');



        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});




    }


    componentWillUnmount(){

        window.$j('body').removeClass('make-request-page single-request-page');
        localStorage.setItem('redirect',this.props.location.pathname);
    }


    render() {



        return (

            <div>

                <div>
                    <Header />

                    <main>
                        <RequestSingle request_long_id={this.props.match.params.request_long_id} public={true}/>
                    </main>
                </div>

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleRequest);