import React, { Component } from "react";
import axios from 'axios';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authHeader } from './helpers/helpers';
import { setHeaderInfo } from "../redux-js/actions/index";
//import 'tui-calendar/dist/tui-calendar.css';
import '../assets/styles/Pages.scss';

import HeaderClient from './header-v2';
import HeaderPublic from './header-public';
import HeaderAdmin from './header-admin';

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class Page extends Component {

    calendarRef = React.createRef();

    constructor(props) {
        super(props);


        this.state = {
            page_fetched: false,
            page:{
                title: '',
                content: ''
            }
        };

    }

    async getPage() {



        //let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        await axios.get(process.env.REACT_APP_API_ENDPOINT + '/portal-page/'+ this.props.match.params.page_slug + '/', authHeader())

            .then(res => {


                if (res.data.page.title === ''){
                    this.props.history.push('/404/' );
                }

                this.setState({
                    page_fetched: true,
                    page: res.data.page
                });

            })

            .catch((error) => {

                this.setState({page_fetched: true});

            });


    }


    componentDidMount(){


        //console.log(this.props.current_user);


        if (this.props.current_user.role === 'client'){


            this.props.setHeaderInfo({client_slug: this.props.current_user.slug, page: 'project-scope'});

        }



        //this.props.setHeaderInfo({client_slug: this.props.match.params.client_slug, post_type: 'profile'});


        this.getPage();


        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }

    render() {


        //console.log(JSON.stringify(this.state.schedules));

        return (

            <div className="page-wrap">

                <div>

                    {
                        this.props.current_user.role === 'client' &&
                        <HeaderClient />
                    }


                    {
                        (this.props.current_user.role === 'administrator' || this.props.current_user.role === 'staff') &&
                        <HeaderAdmin />
                    }


                    {
                        (!this.props.current_user.role) &&
                        <HeaderPublic />
                    }


                    <main>
                        <div className="container-fluid">
                            <div className="wrap">
                                <div className="page-content portal-page-content">

                                    <h2>{this.state.page.title}</h2>

                                    <div dangerouslySetInnerHTML={{__html: this.state.page.content}}></div>

                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Page));