import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
//import { authHeader } from '../helpers/helpers'
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';

//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

//import Dropzone from 'react-dropzone';
//import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}




class ReportsBusinessDirectVsDiscovery extends Component {

    constructor(props) {
        super(props);



        let start_date = moment();
        start_date.subtract(1, 'months'); //89 = 3 months
        start_date.startOf('month');
        start_date.toDate();
        start_date = start_date.format('MM/DD/YYYY');

        let end_date = moment();
        end_date.subtract(1, 'month');
        end_date.endOf('month');
        end_date.toDate();
        end_date = end_date.format('MM/DD/YYYY');




        this.state = {
            current_user: {},
            client_slug: '',
            charts_render: false,
            reports: {
            },

            reports_fetched: false,

            start_date: start_date,
            end_date: end_date,
        };


        this.dateFilter      = this.dateFilter.bind(this);
    }


    getReportDetails(props){

        this.setState({
            reports_fetched: false
        })


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/business-direct-vs-discovery/?start_date='+this.state.start_date+'&end_date='+this.state.end_date )
            .then(res => {

                let reports = res.data.reports;

                this.setState({
                    reports_fetched: true,
                    reports: reports ,
                    charts_render: false,
                }, function () {

                    this.renderCharts();
                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){

        let start_date = moment(this.props.current_report_year_month+'-01', 'YYYY-MM-DD');
        //start_date.subtract(12, 'months');
        start_date.startOf('month');
        start_date.toDate();
        start_date = start_date.format('MM/DD/YYYY');


        let end_date = moment(this.props.current_report_year_month+'-01', 'YYYY-MM-DD');
        end_date.endOf('month');
        end_date.toDate();
        end_date = end_date.format('MM/DD/YYYY');


        this.setState({
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug,
            start_date                  : start_date,
            end_date                    : end_date
        },function () {

            this.getReportDetails(this.props);

        });


    }

    componentWillReceiveProps(nextProps){

    }


    componentWillUnmount(){


    }



    renderCharts(){


        if ( window.$j('#business-direct-vs-discovery-chart').length && !this.state.charts_render ) {

            this.setState({charts_render: true});



            var chart;


            var reports = this.state.reports;
            var series = reports.series;


            for (var x = 0; x < series.data.length; x++){

                series.data[x]['events'] = {
                    //eslint-disable-next-line
                    mouseOver: function (e) {


                        chart.setTitle({text: reports['hover'][e.target.index]});
                    }
                }

            }


            chart = window.Highcharts.chart('business-direct-vs-discovery-chart', {

                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    size: '50%'
                },
                credits: {
                    enabled: false
                },

                title: {
                    text: this.state.reports.chart_text,
                    align: 'center',
                    style: {"color": "#333333", "fontSize": window.$j('body').width() < 767 ? 16 : 22,'lineHeight': window.$j('body').width() < 767 ? 16 : 22},
                    verticalAlign: 'middle',
                    y: -10
                },

                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        size:'100%',
                        dataLabels: {
                            enabled: true,
                            distance: 5,
                            style: {
                                //fontWeight: 'bold',
                                //color: 'white'
                            }
                        },
                        startAngle: 0,
                        endAngle: 360,
                        center: ['50%', '50%'],
                    }
                },


                series: [series]

            });




        } else{

            var ds = this;
            setTimeout(function () {
                ds.renderCharts();
            },1000)
        }

    }


    dateFilter(event, picker) {

        this.setState({
            start_date: picker.startDate.format('MM/DD/YYYY'),
            end_date: picker.endDate.format('MM/DD/YYYY'),

        }, function () {

            this.getReportDetails(this.props);
        });

    }

    render() {



        return (


            <div className="report-block">

                <div className="report-block-content">
                    <div className="report-block-header">
                        <div className="">
                            <h2>Platform and Device Breakdown</h2>
                            <div className="description">{ moment(this.state.start_date, "MM/DD/YYYY").format("MMMM YYYY") }</div>
                        </div>

                        <div className="">


                        </div>

                    </div>

                    {
                        this.state.reports_fetched &&
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="chart-legend-text">
                                    <div dangerouslySetInnerHTML={{__html: this.state.reports.chart_legend}}></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div id="business-direct-vs-discovery-chart"></div>
                            </div>

                        </div>
                    }

                    {
                        !this.state.reports_fetched &&
                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                    }


                </div>





            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsBusinessDirectVsDiscovery);