import React, { Component } from "react";
import axios from 'axios';
import '../../assets/styles/Login.scss';
import logo from '../../assets/images/d6-support-logo.png';
import { withRouter, NavLink } from "react-router-dom";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            email: '',
            password: '',
            btnEnabled: true,
        };



        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);


    }


    componentWillUnmount(){

        window.$j('body').removeClass('login-page');
    }

    componentDidMount(){

        window.$j('body').addClass('login-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});


        if (this.props.match.params.login_as){

            this.handleLoginAs();
        }
    }


    handleLoginAs(){


        let formData = new FormData();

        formData.append('hash', this.props.match.params.login_as);

        axios.post(process.env.REACT_APP_API_AUTH_ENDPOINT + '/d6support/v1/login-as', formData)
            .then(res => {


                localStorage.setItem('redirect','');

                this.setState({'btnEnabled': true});

                localStorage.setItem('currentUser', JSON.stringify(res.data));

                if (res.data.role === "administrator"){

                    window.location.href = '/admin';

                }else{

                    //window.location.href = '/' + res.data.slug + '/requests';


                    if (res.data.portal_first_page_url){
                        window.location.href = res.data.portal_first_page_url;
                    }else{
                        window.location.href = '/portal/projects/' + res.data.slug + '/';
                    }

                }


            })

            .catch((error) => {


                this.setState({
                    errorMsg: 'Unable to login',
                    btnEnabled: true
                });

                //console.log(error.response);

                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                }

                //console.log(error.config);
            });


    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    handleSubmit(event) {

        event.preventDefault();

        this.setState({'btnEnabled': false});

        let formData = new FormData();

        formData.append('username', this.state.email);
        formData.append('password', this.state.password);

        axios.post(process.env.REACT_APP_API_AUTH_ENDPOINT + '/jwt-auth/v1/token', formData)
            .then(res => {


                //localstorage

                this.setState({
                    email: '',
                    password: '',
                    msg: '',
                    errorMsg: '',
                });


                let redirect = localStorage.getItem('redirect');

                localStorage.setItem('redirect','');

                this.setState({'btnEnabled': true});


                localStorage.setItem('currentUser', JSON.stringify(res.data));


                if(redirect){

                    window.location.href = redirect;

                }else{

                    if (res.data.role === "administrator"){

                        window.location.href = '/admin';

                    }else{

                        //window.location.href = '/' + res.data.slug + '/requests';


                        if (res.data.portal_first_page_url){
                            window.location.href = res.data.portal_first_page_url;
                        }else{
                            window.location.href = '/portal/projects/' + res.data.slug + '/';
                        }

                    }

                }

            })

            .catch((error) => {


                this.setState({
                    errorMsg: 'Invalid email or password',
                    btnEnabled: true
                });

                //console.log(error.response);

                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                }

                //console.log(error.config);
            });

    }


    render() {


        return (
            <div className="page-wrap">

                <div>
                    <main>
                        <div className="login-form">



                            <form  onSubmit={this.handleSubmit}  ref={(f) => {this.formRef = f;}}>

                                <div className="login-logo"><img src={logo} className="App-logo" alt="logo" /></div>

                                <div className="form-group">
                                    <input type="text" name="email" placeholder="Username / Email Address" className="form-control" value={this.state.email} onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" name="password" placeholder="Password" className="form-control" value={this.state.password} onChange={this.handleInputChange}/>
                                </div>

                                <button disabled={!this.state.btnEnabled} className="btn btn-primary"><span>Login</span></button>


                                <div className="text-center login-links">
                                    <NavLink to={'/lost-password'}>Lost your password?</NavLink>
                                </div>

                                {
                                    (this.state.errorMsg)
                                        ?(<div className="alert alert-danger">{this.state.errorMsg}</div>)
                                        :('')
                                }

                                {
                                    (this.state.msg)
                                        ?(<div className="alert alert-success">{this.state.msg}</div>)
                                        :('')
                                }
                            </form>
                        </div>
                    </main>
                </div>

            </div>
        );
    }
}

export default withRouter(Login);