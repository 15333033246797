import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

import { NavLink } from "react-router-dom";
import '../../assets/styles/Reports.scss';
//import { authHeader } from '../helpers/helpers'
import { setHeaderInfo } from "../../redux-js/actions/index";
import { connect } from "react-redux";
//import { Editor } from '@tinymce/tinymce-react';
//import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap-daterangepicker/daterangepicker.css';
//import moment from 'moment';

//import Dropzone from 'react-dropzone';
//import { authHeader } from '../helpers/helpers'

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ReportsDashboardMonthlyReports extends Component {

    constructor(props) {
        super(props);


        this.state = {
            current_user: {},
            client_slug: '',
            all_reports: false,
            data: {
            },
            keywords_charts_render: false,
            traffic_charts_render: false,
            cities_charts_render: false,
            device_charts_render: false,
            ups_charts_render: false,
            charts_render: false,
            reports_fetched: false,
         
        };


    }


    getDashboardData(props){

        this.setState({
            reports_fetched: false
        })


        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/client-reports/' + props.client_slug + '/dashboard/' )
            .then(res => {

                let data = res.data;


                this.setState({
                    
                    reports_fetched: true,
                    reports: data['reports'] ,

                }, function () {

                    this.reportSparkLineColumn();
                    
                })

            })

            .catch((error) => {

                this.setState({
                    reports_fetched: true,
                })
            });

    }



    componentDidMount(){

        this.setState({
            current_user                : this.props.current_user,
            client_slug                 : this.props.client_slug,
        },function () {

            this.getDashboardData(this.props);

        });
    }

    componentWillReceiveProps(nextProps){

    }

    componentWillUnmount(){

    }

    numberWithCommas(x) {

        if(x)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    reportSparkLineColumn(){



        /**
         * Create a constructor for sparklines that takes some sensible defaults and merges in the individual
         * chart options. This function is also available from the jQuery plugin as $(element).highcharts('SparkLine').
         */
        window.Highcharts.SparkLine = function (a, b, c) {
            const hasRenderToArg = typeof a === 'string' || a.nodeName;
            let options = arguments[hasRenderToArg ? 1 : 0];
            const defaultOptions = {
                chart: {
                    renderTo: (options.chart && options.chart.renderTo) || (hasRenderToArg && a),
                    backgroundColor: null,
                    borderWidth: 0,
                    type: 'area',
                    margin: [2, 0, 2, 0],
                    width: 120,
                    height: 20,
                    style: {
                        overflow: 'visible'
                    },
                    // small optimalization, saves 1-2 ms each sparkline
                    skipClone: true
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPositions: []
                },
                yAxis: {
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    tickPositions: [0]
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    hideDelay: 0,
                    outside: true,
                    shared: true
                },
                plotOptions: {
                    series: {
                        animation: false,
                        lineWidth: 1,
                        shadow: false,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        marker: {
                            radius: 1,
                            states: {
                                hover: {
                                    radius: 2
                                }
                            }
                        },
                        fillOpacity: 0.25
                    },
                    column: {
                        negativeColor: '#910000',
                        borderColor: 'silver'
                    }
                }
            };

            options = window.Highcharts.merge(defaultOptions, options);

            return hasRenderToArg ?
                new window.Highcharts.Chart(a, options, c) :
                new window.Highcharts.Chart(options, b);
        };

        const
            tds = Array.from(document.querySelectorAll('td[data-sparkline]')),
            fullLen = tds.length;

        let n = 0;

        // Creating 153 sparkline charts is quite fast in modern browsers, but IE8 and mobile
        // can take some seconds, so we split the input into chunks and apply them in timeouts
        // in order avoid locking up the browser process and allow interaction.


        function doChunk() {

            const time = +new Date(),
                len = tds.length;

            for (let i = 0; i < len; i += 1) {
                const td = tds[i];
                const stringdata = td.dataset.sparkline;
                const arr = stringdata.split('; ');
                const data = arr[0].split(', ').map(parseFloat);
                const chart = {};



                if (arr[1]) {
                    chart.type = arr[1];
                }

                window.Highcharts.SparkLine(td, {
                    series: [{
                        data: data,
                        pointStart: 1
                    }],
                    tooltip: {
                        formatter: function(){
                            //console.log();
                            return '<span style="font-size: 10px">' + td.dataset.month +' '+ td.dataset.weekdates.split(",")[this.x-1]+' (Week ' + this.x + '):</span><br/><b>'+this.y+'</b> Sessions'
                        }


                        //headerFormat: '<span style="font-size: 10px">Week ' + td.dataset.weekdates.split(",")[{point.x}]+':</span><br/>',
                        //pointFormat: '<b>{point.y}</b> Sessions'
                    },
                    chart: chart
                });

                n += 1;

                // If the process takes too much time, run a timeout to allow interaction with the browser
                if (new Date() - time > 500) {
                    tds.splice(0, i + 1);
                    setTimeout(doChunk, 0);
                    break;
                }

                // Print a feedback on the performance
                if (n === fullLen) {
                    //document.getElementById('result').innerHTML = 'Generated ' + fullLen + ' sparklines in ' + (new Date() - start) + ' ms';
                }
            }
        }
        doChunk();


    }

    
    render() {


        return (


            <div>

                <div className="report-block">

                    <div className="report-block-content">
                        <div className="report-block-header">
                            <div className="">
                                <h2>Monthly Reports</h2>
                            </div>

                            <div className="">


                            </div>

                        </div>

                        {
                            !this.state.reports_fetched &&
                            <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>
                        }

                        {
                            this.state.reports_fetched &&
                            <div>

                                <div className="report-box">
                                    <div className="table-wrap">
                                        <table cellPadding="5" cellSpacing="5" className="monthly-reports-table">

                                            <thead>
                                                <tr>
                                                    <th>Month/Year</th>
                                                    <th>Users</th>
                                                    <th>Sessions</th>
                                                    <th>Pageviews</th>
                                                    <th>Sessions Per Week</th>
                                                </tr>
                                            </thead>

                                            {
                                                this.state.reports.map((report,i) =>
                                                    <tr key={i} style={{display: !this.state.all_reports && i > 9 ? 'none':'table-row'}}>
                                                        <td>
                                                            <NavLink to={'/portal/reports/'+this.state.client_slug+'/'+report.date+'/highlights/'}>{report.format}</NavLink>
                                                        </td>
                                                        <td>{this.numberWithCommas(report.users)}</td>
                                                        <td>{this.numberWithCommas(report.sessions)}</td>
                                                        <td>{this.numberWithCommas(report.page_views)}</td>
                                                        <td data-sparkline={report.sparkline} data-weekdates={report.week_day_range} data-month={report.month}></td>
                                                    </tr>

                                                )
                                            }
                                        </table>
                                    </div>
                                </div>

                                {
                                    !this.state.all_reports && this.state.reports.length > 10 &&

                                    <div style={{paddingTop:'60px', marginBottom: '-20px'}}>
                                        <button className="btn btn-primary" onClick={()=>this.setState({all_reports: true})}><span>View All</span></button>
                                    </div>
                                }

                            </div>
                        }


                    </div>

                </div>


            </div>

        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportsDashboardMonthlyReports);