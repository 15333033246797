import React, { Component } from "react";
import axios from 'axios';
import SubscribeModal from  './partials/subscribe-modal';
//import PaymentPolicies from './partials/payment-policies';

import '../../assets/styles/Billing.scss';


class Billing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subscription_to_cancel:{},
            billing:{

            },

            billing_fetched: false,
            input:{
                cancel_text: ''
            }
        };


        this.onSubscribe                = this.onSubscribe.bind(this);
        this.handleCancelInputChange    = this.handleCancelInputChange.bind(this);
        this.doCancelSubscription       = this.doCancelSubscription.bind(this);
    }

    handleCancelInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;


        var input = this.state.input;
        input[name] = value;

        this.setState({input});

        if (name === 'cancel_text'){
            if (value.toUpperCase() === 'CANCEL'){

                window.$j('#cancel-subscription-btn').removeAttr('disabled');
            }
            else{
                window.$j('#cancel-subscription-btn').attr('disabled','disabled');

            }
        }
    }


    componentWillUnmount(){

        window.$j('.sub-nav').css({display:'none'});
        window.$j('body').removeClass('client-account-page');
    }


    componentDidMount(){


        this.setState({billing_fetched: false});

        //console.log(this.props.match.params.client);

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/billing', headers)
            .then(res => {


                if (res.data.billing){

                    this.setState({
                        billing: res.data.billing,
                    })
                }

                this.setState({billing_fetched: true});

            })

            .catch((error) => {


                window.location.href = '/login';
                //this.setState({billing_fetched: true});
                //console.log(error.response.data);

            });


        window.$j('.sub-nav').css({display:'block'});
        window.$j('body').addClass('client-account-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }

    onSubscribe(plan){

        let billing = this.state.billing;
        billing.plan = plan;

        this.setState({billing: billing});

        setTimeout(function () {
            window.$j('#paymentModal').modal('toggle');
            window.$j('.modal-backdrop').remove();
        },3000);

    }

    doCancelSubscription(event){


        event.preventDefault();

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        let subscription_to_cancel = this.state.billing.plan;

        console.log(subscription_to_cancel);

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/plan/cancel-subscription', { subscription_to_cancel }, headers)
            .then(res => {

                if(res.data.success){

                    this.setState({cancelMsg: res.data.msg});

                    setTimeout(function () {
                        window.location.reload();
                    },3000)

                }else{

                    this.setState({cancelErrorMsg: res.data.msg});

                }

            })

            .catch((error) => {

            });


        return false;

    }

    render() {

        let cancelErrorMsg            = this.state.cancelErrorMsg;
        let cancelMsg                 = this.state.cancelMsg;


        if (!this.state.billing_fetched){

            return (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>);
        }



        if ( !this.state.billing.plan.plan_id && !this.state.billing.text){

            return(

                <div>
                    <h2>BILLING</h2>

                    <div>No billing services to show</div>



                </div>
            )

        }



        if ( !this.state.billing.plan.plan_id ){

            return(

                <div>
                    <h2>BILLING</h2>

                    <div className="billing-text-section service-plan-content-text">
                        <div dangerouslySetInnerHTML={{__html: this.state.billing.text}}></div>
                    </div>

                </div>
            )

        }

        return (
            <div>
                <h2>BILLING</h2>

                <div className="service-item">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <strong>{this.state.billing.plan.service_name} - ${this.state.billing.plan.cost}/{this.state.billing.plan.interval}</strong>
                            </div>
                            <div>
                                {
                                    (this.state.billing.plan.charged_count >= 1)
                                        ?
                                        (<span>Next Billing Date:</span>)
                                        :
                                        (<span>Billing Date:</span>)
                                }

                                 <span className="billing-date">{this.state.billing.plan.next_billing_date}</span>
                            </div>
                        </div>
                        <div className="col-md-4 last">
                            {
                                (parseInt(this.state.billing.plan.subscribed))
                                ?
                                    (
                                        <div className="text-center">
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#paymentModal"><span>Update Card</span></button>
                                            <br />
                                            <span className="cancel-link" data-toggle="modal" data-target="#cancelSubscriptionModal">Cancel Subscription</span>
                                        </div>

                                    )
                                :
                                    (<button className="btn btn-primary" data-toggle="modal" data-target="#paymentModal"><span>Subscribe</span></button>)
                            }

                        </div>

                    </div>
                </div>

                <div className="billing-text-section service-plan-content-text">
                    <div dangerouslySetInnerHTML={{__html: this.state.billing.text}}></div>
                </div>

                <SubscribeModal clientSubscribe={this.onSubscribe} plan_id={this.state.billing.plan.plan_id} />

                {
                    (parseInt(this.state.billing.plan.subscribed) !== 0) &&

                    <div className="modal fade" id="cancelSubscriptionModal" tabIndex={-1} role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="paymentModalLabel">Cancel Subscription</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <form onSubmit={this.doCancelSubscription}>

                                        <p>Are you sure you want to cancel your subscription?<br />Type <strong>CANCEL</strong> to confirm.</p>

                                        <div className="form-group">
                                            <input name="cancel_text" autoComplete="off" id="confirm-cancel-text" value={this.state.input.cancel_text} type="text" className="form-control" onChange={this.handleCancelInputChange}/>
                                        </div>

                                        <button id="cancel-subscription-btn" type="submit" className="btn btn-primary" disabled ><span>Submit</span></button>


                                        <div style={{marginTop:'20px'}}>
                                            {cancelErrorMsg &&

                                            <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: cancelErrorMsg}}></div>
                                            }

                                            {cancelMsg &&
                                            <div className="alert alert-success" dangerouslySetInnerHTML={{__html: cancelMsg}}></div>
                                            }
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                }



                {/*<PaymentPolicies payment_policies={this.state.billing.payment_policies} />*/}

            </div>
        );
    }
}

export default Billing;