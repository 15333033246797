import React, { Component } from "react";
import Header from '../header-public';
import {StripeProvider, Elements} from 'react-stripe-elements';
import MakeAPaymentForm from './partials/make-a-payment-form';
//import { Link } from "react-router-dom";


import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}





class MakeAPayment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            payment: {
                first_name:'',
                last_name: '',
                email: '',
                post_name: '',
                token: ''
            }
        };



    }

    componentDidMount(){

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

        window.$j('header .main-menu').css({display:'none'});

    }

    componentWillUnmount(){
        window.$j('header .main-menu').css({display:'block'});
    }

    onChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    focus = () => {
        this.editor.focus();
    };

    render() {

        return (

        <div className="page-wrap">

            <div>

                <Header />

                <main>
                    <div className="container-fluid">
                        <div className="wrap">


                            <div className="text-center page-content"  style={{maxWidth:'500px', margin: '0  auto'}}>

                                <div>
                                    <h2>Make a Payment</h2>

                                    <StripeProvider apiKey={this.props.site_details.stripe_publishable_key}>
                                        <Elements>
                                            <MakeAPaymentForm />
                                        </Elements>
                                    </StripeProvider>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>


        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MakeAPayment);