import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from '../assets/images/d6-support-logo.png';
import '../assets/styles/Header.scss';
import MobileNav from './mobile-nav';

import { connect } from "react-redux";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {

    };
}



class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            client: {

            },

            current_user:{
                photo: '',
                role: '',
                photo_letter: 'D'
            },

            photo_letter : ''
        }

        this.forceRerender = this.forceRerender.bind(this);

    }

    componentDidMount(){

        this.setState({current_user: this.props.current_user});


        if (this.props.current_user.first_name){
            this.setState({photo_letter : this.props.current_user.first_name.charAt(0)});
        }



    }


    componentWillReceiveProps(nextProps) {

        this.setState({client: nextProps.client});

        //this.setState({current_user: nextProps.current_user});
        //this.setState({ data: nextProps.data });

    }


    forceRerender(){


        //this is to fix the NavLink activeClassName
        this.setState({rand: Math.random()});
    }

    render() {


        if (this.state.current_user.wp_user_id){


            if (this.state.current_user.role === 'client'){


                return (

                    <div>
                        <MobileNav />

                        <header>

                            <div className="container-fluid">
                                <a href="#nav-open" className="breadcrumbs"><span></span></a>
                                <div className="header-wrap">
                                    <Link to={'/' + this.state.current_user.slug + '/request-support'} className="logo"><img src={logo} className="App-logo" alt="logo" /></Link>


                                    {
                                        (this.state.current_user.logo)
                                            ?
                                                <div className="client-logo"><Link to={'/' + this.state.current_user.slug + '/requests'}><img src={this.state.current_user.logo} alt=""/></Link></div>
                                            :
                                                ''
                                    }


                                    <div className="logged-user">
                                        <div>Hello, <Link to={'/' + this.state.current_user.slug + '/profile'}><span>{this.state.current_user.first_name}</span></Link></div>
                                        <div><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></div>

                                        <Link to={'/' + this.state.current_user.slug + '/profile'}>
                                            <div className="photo">

                                                {
                                                    (this.state.current_user.photo)
                                                        ?(<img src={this.state.current_user.photo} alt=""/>)
                                                        :(<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                                }

                                            </div>
                                        </Link>
                                    </div>
                                    <nav>

                                        <ul className="main-menu">

                                            <li>
                                                <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/' + this.state.current_user.slug + '/requests'}><span>Website Support</span></NavLink>
                                            </li>

                                            <li>
                                                <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/' + this.state.current_user.slug + '/profile'}><span>Profile</span></NavLink>
                                            </li>

                                            {/*<li>*/}
                                                {/*<NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/availability'}><span>D6 Availability</span></NavLink>*/}
                                            {/*</li>*/}
                                        </ul>

                                        <Link to={'/' + this.state.current_user.slug + '/request-support'} className="btn btn-primary btn-sm"><span>Request Work</span></Link>

                                    </nav>
                                </div>
                            </div>

                        </header>
                    </div>

                )


            }else {


                return (

                    <div>
                        <MobileNav />

                        <header>


                            <div className="container-fluid">
                                <a href="#nav-open" className="breadcrumbs"><span></span></a>
                                <div className="header-wrap">
                                    <Link to={'/admin'} className="logo"><img src={logo} className="App-logo" alt="logo"/></Link>

                                    <div className="logged-user">
                                        <div>Hello, <Link to="/admin/"><span>{this.state.current_user.first_name}</span></Link>
                                        </div>
                                        <div><Link to={'/logout'} className="signout-link"><span>Sign-Out</span></Link></div>

                                        <Link to="/admin/">
                                            <div className="photo">

                                                {
                                                    (this.state.current_user.photo)
                                                        ? (<img src={this.state.current_user.photo} alt=""/>)
                                                        : (<span className="photo-letter"><i className="fas fa-user"></i></span>)
                                                }

                                            </div>
                                        </Link>
                                    </div>
                                    <nav>
                                        <ul className="main-menu">
                                            <li>
                                                <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/admin'}><span>Requests</span></NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/admin/project-books/'}><span>Project Books</span></NavLink>
                                            </li>
                                            {/*<li>*/}
                                                {/*<NavLink onClick={this.forceRerender} exact={true} activeClassName='is-active' to={'/availability'}><span>D6 Availability</span></NavLink>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </header>
                    </div>

                )

            }



        }


        else{



            return (

                <div>
                    <MobileNav />

                    <header>
                        <div className="container-fluid">
                            <a href="#nav-open" className="breadcrumbs"><span></span></a>
                            <div className="header-wrap">
                                <Link to={'/request'} className="logo"><img src={logo} className="App-logo" alt="logo" /></Link>

                                <div className="logged-user" style={{padding:'10px 0 0'}}>
                                    <div>Existing D6 Support customer? <Link to={'/login'}><span>Sign-in</span></Link></div>
                                </div>
                                <nav>

                                    <ul className="main-menu" style={{height:'0px',margin:'0px'}}>
                                        <li>&nbsp;</li>
                                    </ul>

                                    {/*<Link to={'/sign-up'} className="btn btn-primary btn-sm"><span>Sign-up</span></Link>*/}

                                </nav>
                            </div>
                        </div>
                    </header>
                </div>

            )

        }


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);