import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";


//import Header from './partials/header'
import Header from '../header-v2'
import ProjectBook from './project-book'
import Projects from '../hourly-support/projects'
import ProjectApprovalForm from '../hourly-support/project-approval-form';
import MakeRequest from '../hourly-support/make-request'
import SingleRequestClient from '../hourly-support/single-request-client'
import Reports from '../hourly-support/reports'


const mapStateToProps = state => {
    return { ...state };
};




function mapDispatchToProps(dispatch) {
    return {

    };
}


class ProjectBookHome extends Component {

    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);
    }

    componentDidMount(){

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

        return (

            <div className="page-wrap project-book-page-wrap">

                <div>

                    <Header />

                    <main>

                        <Switch>
                            <Route path="/portal/projects/:client_slug/make-request" component={MakeRequest}/>
                            <Route path="/portal/projects/:client_slug/request/:request_long_id" component={SingleRequestClient}/>
                            <Route path="/portal/projects/:client_slug/:status" component={Projects}/>
                            <Route path="/portal/projects/:client_slug" component={Projects}/>
                            <Route path="/portal/projects/" component={Projects}/>
                            <Route path="/portal/project/:project_scope_slug" component={ProjectApprovalForm}/>
                            <Route path={`/portal/pages/:project_book_slug`} component={ProjectBook}  />


                            <Route path="/portal/reports/:client_slug/monthly-reports/" component={Reports}/>
                            <Route path="/portal/reports/:client_slug/:report_year_month/:page" component={Reports}/>
                            <Route path="/portal/reports/:client_slug/:report_year_month/" component={Reports}/>
                            <Route path="/portal/reports/:client_slug" component={Reports}/>

                        </Switch>

                    </main>
                </div>

            </div>



        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectBookHome));