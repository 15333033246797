import React, { Component } from "react";
//import Header from '../header-v2';
import axios from 'axios';

//import { Link } from "react-router-dom";
import '../../assets/styles/Projects.scss';
import { connect } from "react-redux";
import { setHeaderInfo } from "../../redux-js/actions/index";

const mapStateToProps = state => {
    return { ...state };
};


function mapDispatchToProps(dispatch) {
    return {
        setHeaderInfo: header_info => dispatch(setHeaderInfo(header_info))
    };
}


class ProjectApprovalForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            updateErrorMsg: '',
            errorMsg: '',
            msg: '',
            btnEnabled: true,
            agreed: false,
            full_name: '',
            project: {
                title:'',
                items:[],
                status: 'in-progress',
            },

            client:{},

            terms:{
                title:'',
                content:'',
            },

            project_scope_fetched: false,
            current_user: {},

        };

        this.handleInputChange  = this.handleInputChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
    }

    componentDidMount(){





        this.props.setHeaderInfo({page_slug: this.props.match.params.project_scope_slug, page: 'project-scope-single'});

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/project-scope-info/' + this.props.match.params.project_scope_slug + '/' )
            .then(res => {


                let project = res.data.project;


                if(project) {

                    this.setState({
                        project_scope_fetched: true,
                        project: project,
                        client: res.data.client,
                        terms: res.data.terms
                    });

                    if (project.status !== 'pending') {

                        this.props.history.push('/projects/' + res.data.client.slug + '/');
                    }

                }

                else{


                    if (this.props.current_user.role === 'client' || this.props.current_user.role === 'administrator'){


                        window.location.href = '/projects/' + this.props.current_user.slug + '/';

                    }else{

                        window.location.href = '/';

                        this.props.history.push('/' );
                    }

                }

            })

            .catch((error) => {

                this.setState({
                    project_scope_fetched: true,
                })
            });


        this.setState({
            current_user:this.props.current_user
        });


        if (this.props.current_user.role === undefined){

            //this.props.history.push('/login/');
        }


        if (this.props.current_user.role !== 'client' || this.props.current_user.role !== 'administrator'){

            //this.props.history.push('/login/');
        }

    }


    componentWillUnmount(){

        localStorage.setItem('redirect',this.props.location.pathname);

    }


    handleSubmit(){

        this.setState({btnEnabled: false});
        this.setState({errorMsg: '', msg: ''});

        if (this.state.full_name === '') {
            this.setState({errorMsg: 'Please enter your full name'});
            return false;
        }

        if (!this.state.agreed) {
            this.setState({errorMsg: 'Please agree to our terms of service'});
            return false;
        }



        let data = {

            full_name: this.state.full_name,
            agreed: this.state.agreed,
            slug: this.props.match.params.project_scope_slug

        };

        let headers = { };

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/approve-project-estimate', data , headers)
            .then(res => {

                if(res.data.success){

                    var project                 = this.state.project;

                    project.status      = 'in-progress';
                    project.approved_by = this.state.full_name;


                    this.props.history.push('/portal/projects/'+this.state.client.slug+'/');

                    this.setState({
                        msg: res.data.msg,
                        project: project,
                    });

                }else{

                    this.setState({
                        errorMsg: res.data.msg
                    });

                }

                this.setState({btnEnabled: true});
            })


            .catch((error) => {

                //console.log(error.response.data);
                this.setState({errorMsg: error.response.data.message});
                this.setState({btnEnabled:true});

            });




    }


    handleInputChange(event) {

        const target = event.target;
        const name = target.name;
        let value = target.value;

        if (target.type === 'checkbox'){
            value = target.checked;
        }

        this.setState({[name]: value});


        //this.setState({[name]: value});


    }

    render() {

        let errorMsg            = this.state.errorMsg;
        let msg                 = this.state.msg;



        return (

            <div>

                <div>

                    {/*<Header post_type="project-scope"/>*/}

                    <div className="project-scope-wrap">
                        <div className="container-fluid">
                            <div className="wrap">


                                {

                                    this.state.project.status === 'pending' && this.state.project_scope_fetched &&

                                    (

                                        <div className="form-wrap">
                                            <div className="page-content">

                                                <div>
                                                    <h4>Approve Estimate</h4>
                                                    <h3>{this.state.project.title}</h3>

                                                    <div className="item-wrap">


                                                        <table className="project-scope-items">
                                                            <tbody>
                                                            {this.state.project.items.map((item, i) =>
                                                                <tr key={i} className="item">
                                                                    <td>{item.title}</td>
                                                                    <td><span className="cost">${item.cost}</span></td>
                                                                </tr>

                                                            )}
                                                            </tbody>
                                                        </table>


                                                        <div className="total">
                                                            PROJECT TOTAL COST
                                                            <span>${this.state.project.total}</span>
                                                        </div>

                                                    </div>


                                                    <div className="field">
                                                        <div className="form-label">Type your Full Name to confirm</div>
                                                        <input type="text" className="form-control" name="full_name" onChange={this.handleInputChange}/>
                                                        <div className="terms">
                                                            <label className="checkbox-ui">
                                                                <span className={this.state.agreed?'active checkbox':'checkbox'}><input type="checkbox" name="agreed" value="1" checked={this.state.agreed} onChange={this.handleInputChange}/></span>

                                                                I accept the <span className="terms-link" data-toggle="modal" data-target="#terms-of-service-modal">terms of service</span>

                                                            </label>
                                                        </div>
                                                    </div>



                                                    <div style={{marginTop:'20px'}}>
                                                        {errorMsg &&

                                                        <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMsg}}></div>
                                                        }

                                                        {msg &&
                                                        <div className="alert alert-success" dangerouslySetInnerHTML={{__html: msg}}></div>
                                                        }
                                                    </div>

                                                    <div className="text-center">
                                                        <button className="btn btn-primary" onClick={this.handleSubmit} disabled={!this.state.agreed || !this.state.full_name || !this.state.btnEnabled}><span>Approve Estimate</span></button>
                                                    </div>

                                                    {
                                                        !this.state.btnEnabled &&
                                                        <div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>

                                                    }

                                                </div>

                                            </div>
                                        </div>

                                    )
                                }


                            </div>

                        </div>
                    </div>
                </div>


                <div className="modal fade" id="terms-of-service-modal" tabIndex={-1} role="dialog" aria-labelledby="terms-of-service-modal-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="terms-of-service-modal-label">{this.state.terms.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div dangerouslySetInnerHTML={{__html: this.state.terms.content}}></div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectApprovalForm);