import React, { Component } from "react";
import axios from 'axios';

import ProfileForm from './partials/profile-form'
import '../../assets/styles/Profile.scss';

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            client: [],
            profile_fetched: false
        };

        this.onProfileUpdate = this.onProfileUpdate.bind(this);
    }

    componentWillUnmount(){

        window.$j('.sub-nav').css({display:'none'});

        window.$j('body').removeClass('client-account-page');
    }

    componentDidMount(){

        //console.log(this.props.match.params.client);

        let headers = { headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token} };

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/hourly-support/profile', headers)
            .then(res => {


                if (res.data.client){

                    this.setState({client: res.data.client})

                }else {

                    this.props.history.push('/');

                }


                this.setState({profile_fetched: true});

            })

            .catch((error) => {

                //this.setState({profile_fetched: true});
                //console.log(error.response.data);
                this.props.history.push('/login');

            });


        window.$j('.sub-nav').css({display:'block'});

        window.$j('body').addClass('client-account-page');

        /***
         * Important
         */
        window.$j('main').css({'height':window.$j(window).height() -70});
        window.$j('main .wrap').css({'min-height':window.$j(window).height() -69});

    }

    onProfileUpdate(client){

        this.setState({client: client});

    }

    render() {


        if (!this.state.profile_fetched){

            return (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>);
        }


        return (
            <div>
                <div className="profile-header">
                    <div className="profile-content">

                        <div className="profile-photo">
                            <img src={this.state.client.photo} alt=""/>
                        </div>

                        <div className="profile-info">
                            <h4>{this.state.client.first_name} {this.state.client.last_name}</h4>
                            <div>{this.state.client.email}</div>
                            <div>{this.state.client.company}</div>

                        </div>
                    </div>
                </div>

                <h2>Profile</h2>

                <ProfileForm profileUpdated={this.onProfileUpdate}  client={this.state.client} />
            </div>
        );
    }
}

export default Profile;